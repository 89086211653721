import React from 'react'

import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'
import { Section } from '@/components/modules/Section'
import { ShareIcons } from '@/components/modules/ShareIcons'

import * as styles from './styles.module.css'

export const MainVariant03 = ({ backgroundImage, shareUrl, backLink }) => {
  return (
    <div className={styles.wrapper}>
      <Section className={styles.section}>
        {(backLink || shareUrl) && (
          <div className={styles.shareLinks}>
            <Link {...backLink} theme="white" variant="link-back" />
            <ShareIcons url={shareUrl} />
          </div>
        )}
      </Section>
      <div className={styles.image}>
        <Image image={backgroundImage} loading="eager" objectFit="cover" objectPosition={'50% 50%'} />
      </div>
    </div>
  )
}
