import React from 'react'
import Select from 'react-select'

import styles from './styles'

export const InputSelect = ({ options = [], value, onChange, ...rest }) => {
  const [selectedOption, setSelectedOption] = React.useState()

  const onSelect = (option) => {
    setSelectedOption(option)
    onChange({ target: { value: option } })
  }

  return (
    <Select
      isSearchable={false}
      options={options}
      styles={styles}
      value={selectedOption}
      onChange={onSelect}
      {...rest}
    />
  )
}
