import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { Image } from '@/components/elements/Image'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const MainVariant09 = ({ title, image, body }) => (
  <div className={styles.container}>
    <Image image={image} className={styles.image} />
    <Section with-padding="true" with-shadow="true">
      <div className={styles.columns}>
        <h2 className={styles.title}>{title}</h2>
        <p>{getContentfulElementParagraph(body)}</p>
      </div>
    </Section>
  </div>
)
