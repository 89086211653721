/**
 * Decamelizes a string with/without a custom separator (underscore by default).
 *
 * @param {string} str String in camelcase
 * @param {string} separator Separator for the new decamelized string.
 */
export const decamelize = (str, separator = '_') =>
  str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase()
