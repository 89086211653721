import React from 'react'

import { useIsMobile } from '@/base/hooks/useIsMobile'

import { List } from '@/components/elements/List'
import { Carousel } from '@/components/modules/Carousel'
import { Rating } from '@/components/modules/Rating'
import { ReviewCard } from '@/components/modules/ReviewCard'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const STYLING = {
  GC: {
    variant: 'gc',
    sectionProps: {
      'with-arrow': 'true',
      'with-background': 'true',
      'with-padding': 'true',
      'with-shadow': 'true',
    },
  },
  MICRO: {
    variant: 'micro',
    sectionProps: {
      'with-background-gradient': 'true',
      'with-padding': 'true',
    },
  },
}

export const Reviews = ({ title, overallRating, numberOfReviews, reviews, variant }) => {
  const isMobile = useIsMobile()

  const styling = STYLING[variant in STYLING ? variant : 'GC']

  return (
    <Section {...styling.sectionProps}>
      <h2 className={styles.title} variant={styling.variant}>
        {title}
      </h2>

      <div className={styles.reviews} variant={styling.variant}>
        <div className={styles.rating} variant={styling.variant}>
          <Rating rating={overallRating} ratingsCount={numberOfReviews} variant={styling.variant} />
        </div>

        {variant === 'GC' && <Carousel items={reviews} component={ReviewCard} itemsInGroup={isMobile ? 1 : 3} />}

        {variant === 'MICRO' && (
          <List
            items={reviews}
            component={ReviewCard}
            itemProps={{ variant: styling.variant }}
            className={styles.list}
          />
        )}
      </div>
    </Section>
  )
}
