// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--P43bu";
export var title = "styles-module--title--jGtb-";
export var upperTitle = "styles-module--upperTitle--JmYd+";
export var calculator = "styles-module--calculator--VnE80";
export var content = "styles-module--content--eC4Qg";
export var calculatorWrapper = "styles-module--calculatorWrapper--vUcCE";
export var rangeWrapper = "styles-module--rangeWrapper--jFH+9";
export var row = "styles-module--row--1YPtF";
export var rowValue = "styles-module--rowValue--1D8UB";
export var rowLabel = "styles-module--rowLabel--Bh9ZQ";
export var toggler = "styles-module--toggler--b0AUo";
export var richTextWrapper = "styles-module--richTextWrapper--EcjQP";
export var richParagraph = "styles-module--richParagraph---hTvS";
export var richHeading4 = "styles-module--richHeading4--XnfAZ";
export var richList = "styles-module--richList--gzvcd";
export var richListItem = "styles-module--richListItem--1s3o-";
export var cta = "styles-module--cta--yAfRX";