import React from 'react'
import { graphql } from 'gatsby'

import { getContentfulBodySection } from '@/base/contentful/getContentfulBodySection'
import { LocationContextProvider } from '@/base/context/LocationContext'
import { LanguageContextProvider } from '@/base/context/LanguageContext'

import { SEO } from '@/components/elements/SEO'
import { Header } from '@/components/sections/Header'
import { Footer } from '@/components/sections/Footer'

const ContentfulPageTemplate = ({
  data: {
    page: { seo, header, body, footer },
  },
  pageContext,
  ...rest
}) => {
  return (
    <LocationContextProvider {...pageContext} {...rest}>
      <LanguageContextProvider {...pageContext} {...rest}>
        <SEO {...(seo || {})} {...rest} />
        {header && <Header {...header} />}
        <main>{body && body.map(getContentfulBodySection)}</main>
        <Footer {...(footer || {})} />
      </LanguageContextProvider>
    </LocationContextProvider>
  )
}

export default ContentfulPageTemplate

export const ContentfulPageQuery = graphql`
  query ContentfulPageQuery($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      seo {
        ... on ContentfulSeo {
          ...FragmentSeo
        }
      }
      header {
        ... on ContentfulHeader {
          ...FragmentHeader
        }
      }
      body {
        ... on ContentfulSectionAccordions {
          ...FragmentAccordions
        }
        ... on ContentfulSectionBenefits {
          ...FragmentBenefits
        }
        ... on ContentfulSectionCards {
          ...FragmentCards
        }
        ... on ContentfulSectionComparison {
          ...FragmentComparison
        }
        ... on ContentfulSectionCta {
          ...FragmentCta
        }
        ... on ContentfulSectionFeatures {
          ...FragmentFeatures
        }
        ... on ContentfulSectionHero {
          ...FragmentHero
        }
        ... on ContentfulSectionLeadForm {
          ...FragmentLeadForm
        }
        ... on ContentfulSectionList {
          ...FragmentList
        }
        ... on ContentfulSectionListing {
          ...FragmentListing
        }
        ... on ContentfulSectionLoanCalculator {
          ...FragmentLoanCalculator
        }
        ... on ContentfulSectionLoanCalculatorWithRichText {
          ...FragmentLoanCalculatorWithRichText
        }
        ... on ContentfulSectionLoanComparingTable {
          ...FragmentLoanComparingTable
        }
        ... on ContentfulSectionLogos {
          ...FragmentLogos
        }
        ... on ContentfulSectionReviews {
          ...FragmentReviews
        }
        ... on ContentfulSectionRequirements {
          ...FragmentRequirements
        }
        ... on ContentfulSectionReportsList {
          ...FragmentReportsList
        }
        ... on ContentfulSectionSteps {
          ...FragmentSteps
        }
        ... on ContentfulSectionSummary {
          ...FragmentSummary
        }
        ... on ContentfulSectionSuccessStories {
          ...FragmentSuccessStories
        }
        ... on ContentfulSectionTwoColumns {
          ...FragmentTwoColumns
        }
        ... on ContentfulSectionQuote {
          ...FragmentQuote
        }
        ... on ContentfulSectionHeroWithBackgroundImage {
          ...FragmentHeroWithBackgroundImage
        }
        ... on ContentfulSectionMain {
          ...FragmentMain
        }
        ... on ContentfulSectionFeatures {
          ...FragmentFeatures
        }
        ... on ContentfulSectionThreeColumnWithImage {
          ...FragmentThreeColumnWithImage
        }
      }
      footer {
        ... on ContentfulFooter {
          ...FragmentFooter
        }
      }
    }
  }
`
