import React from 'react'

import { useWindowListener } from '@/base/hooks/useWindowListener'

const BREAKPOINT = 768

export const useIsMobile = (breakpoint = BREAKPOINT) => {
  const getIsMobile = () => typeof window !== 'undefined' && window.innerWidth < breakpoint

  const [isMobile, setIsMobile] = React.useState(false)

  const handleWindowResize = () => setIsMobile(getIsMobile())

  useWindowListener('resize', handleWindowResize)

  return isMobile
}
