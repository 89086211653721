import React from 'react'

import { Listing01 } from '@/components/sections/Listing/components/Listing01'
import { Listing02 } from '@/components/sections/Listing/components/Listing02'
import { Listing03 } from '@/components/sections/Listing/components/Listing03'
import { Listing04 } from '@/components/sections/Listing/components/Listing04'
import { Listing05 } from '@/components/sections/Listing/components/Listing05'
import { Listing06 } from '@/components/sections/Listing/components/Listing06'
import { Listing07 } from '@/components/sections/Listing/components/Listing07'
import { Listing08 } from '@/components/sections/Listing/components/Listing08'
import { Listing09 } from '@/components/sections/Listing/components/Listing09'
import { Listing10 } from '@/components/sections/Listing/components/Listing10'
import { Listing11 } from '@/components/sections/Listing/components/Listing11'
import { Listing12 } from '@/components/sections/Listing/components/Listing12'
import { Listing13 } from '@/components/sections/Listing/components/Listing13'
import { Listing14 } from '@/components/sections/Listing/components/Listing14'
import { Listing15 } from '@/components/sections/Listing/components/Listing15'
import { Listing16 } from '@/components/sections/Listing/components/Listing16'
import { SectionNotFound } from '@/components/sections/NotFound/Section'

export const Listing = ({ variant = '1', ...props }) => {
  const componentVariants = {
    1: Listing01,
    2: Listing02,
    3: Listing03,
    4: Listing04,
    5: Listing05,
    6: Listing06,
    7: Listing07,
    8: Listing08,
    9: Listing09,
    10: Listing10,
    11: Listing11,
    12: Listing12,
    13: Listing13,
    14: Listing14,
    15: Listing15,
    16: Listing16,
  }

  const Component = componentVariants[variant] || SectionNotFound

  return <Component {...props} />
}
