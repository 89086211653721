import React, { useMemo } from 'react'

import QuotationMarkLeft from '@/assets/icons/quotation_mark_left.png'
import QuotationMarkRight from '@/assets/icons/quotation_mark_right.png'

import { BLOCKS } from '@contentful/rich-text-types'
import { useIsMobile } from '@/base/hooks/useIsMobile'

import { Image } from '@/components/elements/Image'
import { RichText } from '@/components/elements/RichText'

import * as styles from './styles.module.css'

export const SuccessStory = ({ memberImage, memberName, memberDescription, review, withQuotes, numbers }) => {
  const isMobile = useIsMobile()

  function renderRichText(data) {
    if (!data) {
      return null
    }

    return (
      <RichText
        data={data}
        options={{
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => (
              <p className={styles.review_text}>
                {/* TODO: replace with an SVG file */}
                {withQuotes && <img className={styles.left_quotation_mark} src={QuotationMarkLeft} />}
                {children}
                {withQuotes && <img className={styles.right_quotation_mark} src={QuotationMarkRight} />}
              </p>
            ),
          },
        }}
      />
    )
  }

  const renderMember = useMemo(
    () => (
      <div className={styles.member}>
        {memberImage && <Image className={styles.member_image} image={memberImage} />}
        <p className={styles.member_name}>{memberName}</p>
        <p className={styles.member_description}>{memberDescription}</p>
      </div>
    ),
    [memberImage, memberName, memberDescription]
  )

  const renderReviewNumbers = useMemo(() => {
    if (!numbers || numbers?.length === 0) {
      return null
    }
    return (
      <div className={styles.numbers}>
        {numbers.map(({ id, label, number }) => {
          if (!label && !number) {
            return null
          }
          return (
            <div key={id} className={styles.number}>
              <p className={styles.number_value}>{number}</p>
              <p className={styles.number_label}>{label}</p>
            </div>
          )
        })}
      </div>
    )
  }, [numbers])

  return (
    <div className={styles.container}>
      {!isMobile && renderMember}
      <div className={styles.review_container}>
        <div className={styles.review}>
          {isMobile && renderMember}
          {renderRichText(review)}
        </div>
        {renderReviewNumbers}
      </div>
    </div>
  )
}
