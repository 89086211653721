const font = {
  font: 'var(--font)',
  fontSize: '16px',
  lineHeight: '1',
  color: 'hsla(0, 0%, 35%, 1)',
}

export default {
  control: (provided, state) => ({
    ...provided,
    minHeight: 'unset',
    cursor: 'pointer',

    padding: '12px 16px',
    borderRadius: '4px',
    border: state.selectProps.menuIsOpen ? 'solid 1px var(--color--primary-1)' : 'solid 1px hsla(0, 3%, 78%, 1)',
    boxShadow: state.selectProps.menuIsOpen ? '0 0 0 1px inset var(--color--primary-1)' : 'none',
    transition: 'border 0.25s, box-shadow 0.25s',

    '&:hover': {
      outline: 'none',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
    marginLeft: 16,
    transform: `rotateX(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    transition: 'transform 0.25s',

    '&:hover': {
      transform: `rotateX(${state.selectProps.menuIsOpen ? 180 : 0}deg)`,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided) => ({
    ...provided,
    ...font,
    margin: 0,
    padding: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
    border: '1px solid hsla(0, 3%, 78%, 1)',
    boxShadow: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: '4px',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    ...font,
  }),
  option: (provided, state) => ({
    ...provided,
    ...font,

    color: state.isSelected ? `white` : state.isFocused ? `white` : `hsla(0, 0%, 35%, 1)`,

    background: state.isSelected
      ? state.isFocused
        ? `var(--color--primary-2)`
        : `var(--color--primary-1)`
      : state.isFocused
      ? `var(--color--primary-2)`
      : `white`,

    transition: 'color 0.25s, background 0.25s',

    '&:active': {
      background: 'var(--color--primary-1)',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    ...font,
  }),
  singleValue: (provided) => ({
    ...provided,
    ...font,
    overflow: 'visible',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
}
