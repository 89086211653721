import React, { useState } from 'react'
import { BLOCKS } from '@contentful/rich-text-types'

import { useCalculator } from '@/base/hooks/useCalculator'
import { useLanguageContext } from '@/base/context/LanguageContext'

import { Button } from '@/components/elements/Button'
import { RichText } from '@/components/elements/RichText'
import { InputText } from '@/components/elements/Inputs/Text'
import { InputSelect } from '@/components/elements/Inputs/Select'

import { CalculatorResults } from '@/components/modules/CalculatorResults'

import * as styles from './styles.module.css'

const LOAN_AMOUNT = {
  business: {
    defaultValue: { value: 10000 },
  },
}

const PAYMENT_TERM = {
  business: {
    defaultValue: { value: 12 },
  },
}

const MONTHLY_INTEREST_RATE = {
  business: {
    defaultValue: { value: 1 },
  },
}

export const LoanCalculatorWithRichTextSecondary = ({ background, title, body, loanTerms, cta, calculatorType }) => {
  const { t } = useLanguageContext()
  const [resultsVisible, setResultsVisibility] = useState(false)
  const onChangeResultsVisibility = () => setResultsVisibility(!resultsVisible)

  const { setLoanAmount, setPaymentTerm, setMonthlyInterestRate, loanAmount, paymentTerm, monthlyInterestRate } =
    useCalculator(LOAN_AMOUNT, PAYMENT_TERM, MONTHLY_INTEREST_RATE, calculatorType[0])

  const renderForm = () => {
    return (
      <form className={styles.form}>
        <InputText
          type="number"
          value={loanAmount}
          onChange={(e) => setLoanAmount(+e.target.value)}
          variant="micro"
          placeholder={t`calculator_with_rich_text_secondary.money`}
        />
        <InputSelect
          value={paymentTerm}
          onChange={(e) => setPaymentTerm(e.target.value)}
          defaultValue={t`calculator_with_rich_text_secondary.period`[0]}
          options={t`calculator_with_rich_text_secondary.period`}
        />
        <InputSelect
          value={monthlyInterestRate}
          onChange={(e) => setMonthlyInterestRate(e.target.value)}
          defaultValue={t`calculator_with_rich_text_secondary.interest_rate`[0]}
          options={t`calculator_with_rich_text_secondary.interest_rate`}
        />
        <Button onClick={onChangeResultsVisibility}>{t`calculator_with_rich_text_secondary.submit`}</Button>
        <p className={styles.disclaimer}>{loanTerms}</p>
      </form>
    )
  }

  return (
    <div style={{ backgroundImage: `url(${background.file?.url})` }} className={styles.wrapper}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.container}>
          {resultsVisible ? (
            <div className={styles.results}>
              <CalculatorResults
                money={loanAmount}
                period={paymentTerm}
                interestRate={monthlyInterestRate}
                reset={onChangeResultsVisibility}
                cta={cta}
              />
              <p className={styles.disclaimerPadding}>{loanTerms}</p>
            </div>
          ) : (
            renderForm()
          )}
          {body && (
            <div className={styles.richTextWrapper}>
              <RichText
                data={body}
                options={{
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (_node, children) => <p className={styles.richParagraph}>{children}</p>,
                    [BLOCKS.HEADING_4]: (_node, children) => <h4 className={styles.richHeading4}>{children}</h4>,
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
