import React from 'react'

import { MainVariant01 } from '@/components/sections/Main/components/MainVariant01'
import { MainVariant02 } from '@/components/sections/Main/components/MainVariant02'
import { MainVariant03 } from '@/components/sections/Main/components/MainVariant03'
import { MainVariant04 } from '@/components/sections/Main/components/MainVariant04'
import { MainVariant05 } from '@/components/sections/Main/components/MainVariant05'
import { MainVariant06 } from '@/components/sections/Main/components/MainVariant06'
import { MainVariant07 } from '@/components/sections/Main/components/MainVariant07'
import { MainVariant08 } from '@/components/sections/Main/components/MainVariant08'
import { MainVariant09 } from '@/components/sections/Main/components/MainVariant09'
import { MainVariant10 } from '@/components/sections/Main/components/MainVariant10'
import { MainVariant11 } from '@/components/sections/Main/components/MainVariant11'
import { MainVariant12 } from '@/components/sections/Main/components/MainVariant12'
import { MainVariant13 } from '@/components/sections/Main/components/MainVariant13'
import { MainVariant14 } from '@/components/sections/Main/components/MainVariant14'

export const Main = ({ variant = '1', ...props }) => {
  const componentVariants = {
    1: MainVariant01,
    2: MainVariant02,
    3: MainVariant03,
    4: MainVariant04,
    5: MainVariant05,
    6: MainVariant06,
    7: MainVariant07,
    8: MainVariant08,
    9: MainVariant09,
    10: MainVariant10,
    11: MainVariant11,
    12: MainVariant12,
    13: MainVariant13,
    14: MainVariant14,
  }

  const Component = componentVariants[variant]

  return <Component {...props} />
}
