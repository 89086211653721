import React from 'react'

import OrangeDotsSVG from '@/assets/svg/orange-dots.svg'
import OrangeDotsStandingSVG from '@/assets/svg/orange-dots-standing.svg'
import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const FeatureElement = ({ feature }) => <span>• {feature}</span>

export const MainVariant05 = ({ title, cta, featureTags, body }) => {
  const {
    internal: { content: description },
  } = body
  return (
    <Section with-padding="true" with-shadow="true" with-light-background="true">
      <div className={styles.columns}>
        <OrangeDotsSVG className={styles.leftSVG} />
        <div className={styles.heading}>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <h3>{description}</h3>
          {featureTags && featureTags.length > 0 && (
            <List items={featureTags.map((feature) => ({ feature }))} component={FeatureElement} />
          )}
        </div>
        <div className={styles.cta}>
          <Link {...cta} variant="button-default" />
        </div>
        <OrangeDotsStandingSVG className={styles.rightSVG} />
      </div>
    </Section>
  )
}
