import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { useLanguageContext } from '@/base/context/LanguageContext'

import { Image } from '@/components/elements/Image'
import { List } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const Statistic = ({ title, description }) => (
  <div className={styles.statistic}>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
)

export const Summary = ({ title, body, statistics, certificationsTitle, certifications, image }) => {
  const { t } = useLanguageContext()

  return (
    <Section className={styles.section}>
      <h2>{title}</h2>

      {getContentfulElementParagraph(body)}

      <List items={statistics} component={Statistic} className={styles.statistics} />

      <div className={styles.certifications}>
        <h3>{certificationsTitle}</h3>
        <List items={certifications.map((image) => ({ image }))} component={Image} />
      </div>

      <Image image={image} className={styles.image} />
    </Section>
  )
}
