import React, { useMemo } from 'react'

import { Image } from '@/components/elements/Image'
import { RichText } from '@/components/elements/RichText'
import { Link } from '@/components/elements/Link'
import { BLOCKS } from '@contentful/rich-text-types'
import { Section } from '@/components/modules/Section'
import { Carousel } from '@/components/modules/Carousel'

import * as styles from './styles.module.css'
import { SuccessStory } from '../../modules/SuccessStory'

export const SuccessStories = ({
  subtitle,
  successStoriesTitle: title,
  successStoriesDescription: description,
  stories,
  callToAction,
  trustpilotImage,
  trustpilotText,
  withTrustpilotRating,
}) => {
  function renderRichText({ data, withDescription, trustpilotText }) {
    if (!data) {
      return null
    }

    return (
      <RichText
        data={data}
        options={{
          renderNode: {
            [BLOCKS.HEADING_2]: (_node, children) => (
              <h2 className={styles.title} with-description={withDescription ? 'true' : false}>
                {children}
              </h2>
            ),
            [BLOCKS.PARAGRAPH]: (_node, children) =>
              trustpilotText ? (
                <p className={styles.trustpilot_text}>{children}</p>
              ) : (
                <p className={styles.description}>{children}</p>
              ),
          },
        }}
      />
    )
  }

  const renderStories = useMemo(() => {
    if (!stories || stories?.length === 0) {
      return null
    }
    if (stories?.length === 1) {
      const [story] = stories
      return (
        <div className={styles.stories_container}>
          <SuccessStory key={story.id} {...story} />
        </div>
      )
    }
    return (
      <div className={styles.stories_container}>
        <Carousel items={stories} component={SuccessStory} itemsInGroup={1} indicatorSize={12} withArrows={false} />
      </div>
    )
  }, [stories])

  return (
    <Section className={styles.container} with-padding="true">
      {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
      {renderRichText({ data: title, withDescription: !!description })}
      {renderRichText({ data: description })}
      {renderStories}
      {withTrustpilotRating && (
        <div className={styles.trustpilot_rating}>
          {trustpilotImage && <Image className={styles.trustpilot_image} image={trustpilotImage} />}
          {renderRichText({ data: trustpilotText, trustpilotText: true })}
        </div>
      )}
      {callToAction?.label && (
        <div className={styles.call_to_action}>
          <Link {...callToAction} variant="button-default" />
        </div>
      )}
    </Section>
  )
}
