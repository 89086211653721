import React from 'react'

import LogoSVG from '@/assets/svg/logo.svg'

import { useIsMobile } from '@/base/hooks/useIsMobile'
import { useLanguageContext } from '@/base/context/LanguageContext'
import { getCaminoFinancialHomePage } from '@/base/language/getPagePath'

import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'
import { LinkLanguageSelect } from '@/components/modules/LinkLanguageSelect'
import { LeadForm as LeadFormModule } from '@/components/modules/LeadForm'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const LeadForm = ({
  logo,
  title,
  subtitle,
  cta,
  imageDesktop,
  imageMobile,
  isLeadFormDisplayed,
  leadFormTitle,
  type,
  consent,
  buttonSubmit,
}) => {
  const isMobile = useIsMobile()

  const { language_code } = useLanguageContext()

  const homePath = getCaminoFinancialHomePage({ language_code })

  return (
    <div className={styles.wrapper} is-lead-form-displayed={String(isLeadFormDisplayed)}>
      <Section className={styles.navigation}>
        <Link link={{ url: homePath, withUtmParameters: true }}>
          {logo ? <Image image={logo} className={styles.logo} /> : <LogoSVG className={styles.logo} />}
        </Link>
        <LinkLanguageSelect />
      </Section>

      <Section className={styles.section}>
        <div className={styles.title}>
          <h1>{title}</h1>

          {subtitle && <h2>{subtitle}</h2>}

          {cta && (
            <div className={styles.cta}>
              <Link {...cta} variant="button-micro" />
            </div>
          )}
        </div>

        {isLeadFormDisplayed && (
          <LeadFormModule title={leadFormTitle} type={type} consent={consent} buttonSubmit={buttonSubmit} />
        )}
      </Section>

      <div className={styles.image}>
        <Image image={isMobile ? imageMobile : imageDesktop} loading="eager" objectFit="cover" />
      </div>
    </div>
  )
}
