/**
 * Calculates the origantion fee based on the Loan Amount and the type of the loan.
 * @param {number} principal Loan Amount
 * @param {string} type "businnes" or "personal"
 */
export const fee = (principal, type) => {
  if (type === 'business') {
    // formula: if the loan is smaller than 50 000 the fee equals to 6.99% of the Loan Amount, otherwise it is 5%
    return (principal * (principal <= 50000 ? 6.99 : 5)) / 100
  }

  if (type === 'personal') {
    // formula 90$ or 3% of the Loan Amount, whichever is smaller
    return Math.min(90, (principal * 3) / 100)
  }

  return 0
}

/**
 * Calculates fixed monthly loan payment.
 * monthly payment formula: P * (r * (1 + r)^n) / ((1+r)^n - 1), where P = loan amount, r = monthly interest rate, n = number of periods
 * @param {number} principal Loan Amount
 * @param {number} rate Monthly interest rate
 * @param {number} periods The number of payments to be made
 * @returns
 */
export const monthlyPayment = (principal, rate, periods) =>
  principal * ((rate * Math.pow(1 + rate, periods)) / (Math.pow(1 + rate, periods) - 1))

/**
 * Calculates the interest rate of an annuity investment based on constant-amount periodic payments and the assumption of a constant interest rate,
 * @param {number} periods The number of payments to be made
 * @param {number} payment The amount per period to be paid
 * @param {number} present The current value of the annuity
 * @returns
 */
export const rate = (periods, payment, present) => {
  let epsMax = 1e-10
  let iterMax = 20
  let rate = 0.01

  for (let i = 0; i < iterMax; i++) {
    if (rate <= -1) {
      return 0
    }

    let y, f

    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate) * periods
    } else {
      f = Math.pow(1 + rate, periods)
      y = present * f + payment * (1 / rate) * (f - 1)
    }

    if (Math.abs(y) < epsMax) {
      return rate
    }

    let dy

    if (Math.abs(rate) < epsMax) {
      dy = present * periods + payment * periods
    } else {
      f = Math.pow(1 + rate, periods)
      let df = periods * Math.pow(1 + rate, periods - 1)
      dy = present * df + payment * (1 / rate) * df + payment * (-1 / (rate * rate)) * (f - 1)
    }

    rate -= y / dy
  }

  return rate
}

/**
 * Calculates the APR.
 * @param {number} periods The number of payments to be made
 * @param {number} payment The amount per period to be paid
 * @param {number} principal Loan Amount
 * @param {number} fee Origination fee
 * @returns
 */
export const apr = (periods, payment, principal, fee) =>
  (rate(periods, payment, -1 * (principal - fee)) * 12 * 100).toFixed(2)

export default { fee, monthlyPayment, rate, apr }
