import React from 'react'

import { useLanguageContext } from '@/base/context/LanguageContext'
import { useLocationContext } from '@/base/context/LocationContext'
import { getLeadFormSubmissionURL } from '@/base/helpers/getLeadFormSubmissionURL'
import { submitLeadForm } from '@/base/helpers/submitLeadForm'
import { getLoanType } from '@/base/helpers/getLoanType'
import { getLeadFormId } from '@/base/helpers/getLeadFormId'

import { Button } from '@/components/elements/Button'
import { InputCheckbox } from '@/components/elements/Inputs/Checkbox'
import { InputPhone } from '@/components/elements/Inputs/Phone'
import { InputSelect } from '@/components/elements/Inputs/Select'
import { InputText } from '@/components/elements/Inputs/Text'
import { TextSmall } from '@/components/elements/TextSmall'
import { RichText } from '@/components/elements/RichText'
import { useValidatedInput } from './useValidatedInput'

import * as styles from './styles.module.css'

const getConsentLabel = (t, data) => {
  if (data) {
    return <RichText data={data} />
  }

  // falback
  return <span dangerouslySetInnerHTML={{ __html: t`form.tos_checkbox` }} />
}

export const LeadForm = ({ title, type, consent, buttonSubmit }) => {
  const { location, utm_params } = useLocationContext()
  const { t, language_code } = useLanguageContext()

  const [isLoading, setIsLoading] = React.useState()
  const isBusinessType = type === 'business'
  const leadform_id = getLeadFormId({ type, language_code })

  const INPUTS = {
    first_name: {
      component: InputText,
      title: t`form.first_name`,
      inputProps: { variant: 'micro' },
      required: true,
    },
    last_name: {
      component: InputText,
      title: t`form.last_name`,
      inputProps: { variant: 'micro' },
      required: true,
    },
    email: {
      component: InputText,
      title: t`form.email`,
      inputProps: { type: 'email', variant: 'micro' },
      required: true,
      regex: /(?!.*\.\.)(^[^\.][^@\s]+@[^@\s]+\.[^@\s\.]+$)/,
    },
    phone_number: {
      component: InputPhone,
      title: t`form.phone_number`,
      inputProps: { type: 'tel', variant: 'micro' },
      required: true,
      regex: /^\((\d{3})\) (\d{3})-(\d{4})$/,
    },
    time_in_business: {
      component: InputSelect,
      title: t`form.time_in_business`,
      inputProps: { options: t`form.time_in_business_options` },
      required: isBusinessType,
    },
    consent: {
      component: InputCheckbox,
      title: 'Consent',
      inputProps: { label: getConsentLabel(t, consent), defaultChecked: true },
      required: true,
    },
  }

  const [InputFirstName, onFirstNameSubmit] = useValidatedInput(INPUTS.first_name)
  const [InputLastName, onLastNameSubmit] = useValidatedInput(INPUTS.last_name)
  const [InputEmail, onEmailSubmit] = useValidatedInput(INPUTS.email)
  const [InputPhoneNumber, onPhoneSubmit] = useValidatedInput(INPUTS.phone_number)
  const [InputTimeInBusiness, onTimeInBusinessSubmit] = useValidatedInput(INPUTS.time_in_business)
  const [InputConsent, onConsentSubmit] = useValidatedInput(INPUTS.consent)

  const onSubmit = async (e) => {
    e.preventDefault()

    setIsLoading(true)

    let isValid = false

    const [valueFirstName, isFirstNameValid] = onFirstNameSubmit()
    const [valueLastName, isLastNameValid] = onLastNameSubmit()
    const [valueEmail, isEmailValid] = onEmailSubmit()
    const [valuePhone, isPhoneValid] = onPhoneSubmit()
    const [valueTimeInBusiness, isTimeInBusinessValid] = onTimeInBusinessSubmit()
    const [valueConsent, isConsentValid] = onConsentSubmit()

    isValid =
      isFirstNameValid && isLastNameValid && isEmailValid && isPhoneValid && isConsentValid && isTimeInBusinessValid

    if (isValid) {
      const { response, url_params } = await submitLeadForm({
        valueFirstName,
        valueLastName,
        valueEmail,
        valuePhone,
        valueTimeInBusiness,
        valueConsent,

        type,
        language_code,
        location,
        utm_params,
      })

      if (response.status === 200) {
        const loan_type = getLoanType({ type, valueTimeInBusiness })

        window.dataLayer = window.dataLayer || []

        const event = loan_type === 'personal' ? 'pl_lead' : 'bl_lead'
        window.dataLayer.push({
          event,
          variant: leadform_id,
        })

        window.location.href = getLeadFormSubmissionURL({
          language_code,
          years_in_business: isBusinessType ? valueTimeInBusiness?.value : null,
          url_params,
        })
      }

      // TODO: submission error messages will be done "later"
    }

    setIsLoading(false)
  }

  return (
    <div>
      <form noValidate className={styles.form}>
        <h2 className={styles.title}>{title}</h2>

        {InputFirstName}
        {InputLastName}
        {InputEmail}
        {InputPhoneNumber}
        {isBusinessType && InputTimeInBusiness}
        {InputConsent}

        <div className={styles.button}>
          {/* we're not using form's onSubmit, because Segment (3rd party tracking script) is refreshing the page on submit */}
          <Button onClick={onSubmit} is-loading={String(isLoading)} variant="micro">
            {buttonSubmit?.label || t`form.submit`}
          </Button>
          {buttonSubmit?.description && <TextSmall>{buttonSubmit.description}</TextSmall>}
        </div>
      </form>
    </div>
  )
}
