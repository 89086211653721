import React from 'react'

import { LoanCalculatorWithRichTextPrimary } from '@/components/sections/LoanCalculatorWithRichTextPrimary'
import { LoanCalculatorWithRichTextSecondary } from '@/components/sections/LoanCalculatorWithRichTextSecondary'
import { LoanCalculatorTertiary } from '@/components/sections/LoanCalculatorTertiary'

export const LoanCalculatorWithRichText = ({ variant = 'primary', ...props }) => {
  const componentVariants = {
    primary: LoanCalculatorWithRichTextPrimary,
    secondary: LoanCalculatorWithRichTextSecondary,
    tertiary: LoanCalculatorTertiary,
  }

  const Component = componentVariants[variant]

  return <Component {...props} />
}
