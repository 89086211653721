import React from 'react'

import { List } from '@/components/elements/List'
import { Accordion } from '@/components/modules/Accordion'
import { NoHardInquiryCTAButton } from '@/components/modules/CTA/NoHardInquiryCTAButton'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const Accordions = ({ title, accordions, cta }) => (
  <Section with-padding="true">
    <h2 className={styles.title} variant="micro">
      {title}
    </h2>

    <List items={accordions} component={Accordion} className={styles.accordions} />

    <NoHardInquiryCTAButton {...cta} />
  </Section>
)
