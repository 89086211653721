import React from 'react'

import { Image } from '@/components/elements/Image'

import * as styles from './styles.module.css'

export const Step = ({ title, icon }) => (
  <div className={styles.wrapper}>
    <div className={styles.icon}>
      <Image image={icon} height="100" width="100" />
    </div>
    <h3 className={styles.title}>{title}</h3>
  </div>
)
