// extracted by mini-css-extract-plugin
export var container = "styles-module--container--r-JFK";
export var member = "styles-module--member--0DBS6";
export var member_image = "styles-module--member_image--OJxSo";
export var member_name = "styles-module--member_name--DkPQI";
export var member_description = "styles-module--member_description--M6FTX";
export var review_container = "styles-module--review_container--cHJ45";
export var review = "styles-module--review--NJPWU";
export var review_text = "styles-module--review_text--Kvfqw";
export var left_quotation_mark = "styles-module--left_quotation_mark--v-9qX";
export var right_quotation_mark = "styles-module--right_quotation_mark--EmBys";
export var numbers = "styles-module--numbers--5W-8r";
export var number = "styles-module--number--LVgag";
export var number_value = "styles-module--number_value--042CU";
export var number_label = "styles-module--number_label--rtH9g";