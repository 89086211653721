import React from 'react'
import CountUp from 'react-countup'

import { Image } from '@/components/elements/Image'
import { List as ElementList } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const isNumber = (text) => !isNaN(parseInt(text))

const ListingItem = ({ title, description }) => (
  <div className={styles.listItem}>
    <p className={styles.title}>
      {isNumber(title) ? <CountUp separator="," prefix="$" suffix="+" end={parseInt(title)} /> : title}
    </p>

    <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
  </div>
)

export const Listing09 = ({ title, items, image }) => (
  <Section with-padding="true" className={styles.section}>
    <div className={styles.infos}>
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
      <ElementList items={items} component={ListingItem} className={styles.list} />
    </div>
    <div className={styles.imageContainer}>
      <Image image={image} />
    </div>
  </Section>
)
