import React from 'react'

import { InputRange } from '@/components/elements/Inputs/Range'

import * as primaryStyles from './primaryStyles.module.css'
import * as secondaryStyles from './secondaryStyles.module.css'

export const Range = ({
  title,
  step = 1,
  min = 0,
  max = 100,
  defaultValue = 50,
  unitFormatter = (unit) => unit,
  onValueUpdate,
  secondary = false,
}) => {
  const [value, setValue] = React.useState(defaultValue)

  const onValueUpdated = (val) => {
    setValue(val)
    onValueUpdate?.(val)
  }

  const styles = secondary ? secondaryStyles : primaryStyles

  return (
    <div className={styles.range}>
      <div>
        {/* TODO: add a proper id */}
        <label htmlFor={title} className={styles.label}>
          {title}
        </label>
        <span className={styles.value}>{unitFormatter(value)}</span>
      </div>
      <InputRange
        id={title}
        step={step}
        min={min}
        max={max}
        defaultValue={defaultValue}
        onValueUpdate={onValueUpdated}
        secondary={secondary}
      />
      {!secondary && (
        <div className={styles.limits}>
          <span>{unitFormatter(min)}</span>
          <span>{unitFormatter(max)}</span>
        </div>
      )}
    </div>
  )
}
