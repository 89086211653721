import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { useLanguageContext } from '@/base/context/LanguageContext'

import { Image } from '@/components/elements/Image'
import { Logos } from '@/components/modules/Logos'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const MainVariant11 = ({ title, body, image, certifications }) => {
  const { t } = useLanguageContext()
  return (
    <Section with-padding="true" with-shadow="true">
      <div className={styles.columns}>
        <div className={styles.heading}>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          {getContentfulElementParagraph(body)}
        </div>
        {certifications && certifications.length > 0 && (
          <div className={styles.logos}>
            <Logos title={t`featured`} logos={certifications.map((image) => ({ image }))} />
          </div>
        )}
      </div>

      {image && (
        <div className={styles.imageContainer}>
          <Image image={image} className={styles.image} />
        </div>
      )}
    </Section>
  )
}
