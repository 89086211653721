import React from 'react'

import { Image } from '@/components/elements/Image'
import { List } from '@/components/elements/List'

import * as styles from './styles.module.css'

export const Logos = ({ title, logos }) => (
  <div className={styles.logos}>
    <h3 className="uppercase">{title}</h3>
    <List items={logos} component={Image} />
  </div>
)
