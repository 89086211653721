import React from 'react'

import * as primaryStyles from './primaryStyles.module.css'
import * as secondaryStyles from './secondaryStyles.module.css'

export const InputRange = ({ step = 1, min = 0, max = 100, defaultValue = 50, secondary = false, onValueUpdate }) => {
  const [value, setValue] = React.useState(defaultValue)
  const styles = secondary ? secondaryStyles : primaryStyles

  const onChange = (e) => {
    setValue(e.target.value)
    onValueUpdate?.(e.target.value)
  }

  return (
    <input
      type="range"
      step={step}
      min={min}
      max={max}
      style={{ '--min': min, '--max': max, '--val': value }}
      className={styles.input}
      onChange={onChange}
      value={value}
    />
  )
}
