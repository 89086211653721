import React from 'react'

import { useLanguageContext } from '@/base/context/LanguageContext'

import { Stars } from '@/components/elements/Stars'

import * as styles from './styles.module.css'

export const Rating = ({ rating = 0, maxRating = 5, ratingsCount = 0, variant }) => {
  const { t } = useLanguageContext()

  return (
    <div className={styles.rating} variant={variant}>
      <h4>
        {rating}/{maxRating}
      </h4>
      <Stars filledIn={rating} count={maxRating} />
      <h5>
        {ratingsCount} {t`reviews`}
      </h5>
    </div>
  )
}
