import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'

export const RichText = ({ data, options }) => {
  const defaultOptions = {
    ...options,
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => <>{children}</>,
      ...options?.renderNode,
    },
  }

  return renderRichText(data, defaultOptions)
}
