import React from 'react'

import { Accordions } from '@/components/sections/Accordions'
import { Benefits } from '@/components/sections/Benefits'
import { Cards } from '@/components/sections/Cards'
import { Comparison } from '@/components/sections/Comparison'
import { Features } from '@/components/sections/Features'
import { Cta } from '@/components/sections/Cta'
import { Hero } from '@/components/sections/Hero'
import { HeroWithBackgroundImage } from '@/components/sections/HeroWithBackgroundImage'
import { LeadForm } from '@/components/sections/LeadForm'
import { List } from '@/components/sections/List'
import { LoanCalculatorTertiary } from '@/components/sections/LoanCalculatorTertiary'
import { LoanCalculatorWithRichText } from '@/components/sections/LoanCalculatorWithRichText'
import { LoanComparingTable } from '@/components/sections/LoanComparingTable'
import { Logos } from '@/components/sections/Logos'
import { Reviews } from '@/components/sections/Reviews'
import { Requirements } from '@/components/sections/Requirements'
import { ReportsList } from '@/components/sections/ReportsList'
import { Steps } from '@/components/sections/Steps'
import { SectionNotFound } from '@/components/sections/NotFound/Section'
import { Summary } from '@/components/sections/Summary'
import { SuccessStories } from '@/components/sections/SuccessStories'
import { ThreeColumnWithImage } from '@/components/sections/ThreeColumnWithImage'
import { TwoColumns } from '@/components/sections/TwoColumns'
import { Quote } from '@/components/sections/Quote'
import { Main } from '@/components/sections/Main'
import { Listing } from '@/components/sections/Listing'

const body = {
  ContentfulSectionAccordions: Accordions,
  ContentfulSectionBenefits: Benefits,
  ContentfulSectionCards: Cards,
  ContentfulSectionComparison: Comparison,
  ContentfulSectionCta: Cta,
  ContentfulSectionFeatures: Features,
  ContentfulSectionHero: Hero,
  ContentfulSectionHeroWithBackgroundImage: HeroWithBackgroundImage,
  ContentfulSectionLeadForm: LeadForm,
  ContentfulSectionList: List,
  ContentfulSectionListing: Listing,
  ContentfulSectionLoanCalculator: LoanCalculatorTertiary,
  ContentfulSectionLoanCalculatorWithRichText: LoanCalculatorWithRichText,
  ContentfulSectionLoanComparingTable: LoanComparingTable,
  ContentfulSectionMain: Main,
  ContentfulSectionLogos: Logos,
  ContentfulSectionReviews: Reviews,
  ContentfulSectionRequirements: Requirements,
  ContentfulSectionReportsList: ReportsList,
  ContentfulSectionSteps: Steps,
  ContentfulSectionSummary: Summary,
  ContentfulSectionSuccessStories: SuccessStories,
  ContentfulSectionThreeColumnWithImage: ThreeColumnWithImage,
  ContentfulSectionTwoColumns: TwoColumns,
  ContentfulSectionQuote: Quote,
}

export const getContentfulBodySection = ({ id, ...props }) => {
  const Section = body[props.__typename] || SectionNotFound

  return <Section key={id} {...props} />
}
