import React from 'react'

import CheckmarkSVG from '@/assets/svg/checkmark.svg'
import LogoSVG from '@/assets/svg/logo.svg'
import XSVG from '@/assets/svg/x.svg'

import { List } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const Feature = ({ feature, Icon = XSVG }) => (
  <div className={styles.feature}>
    <Icon height="16" width="16" />
    <p dangerouslySetInnerHTML={{ __html: feature }} />
  </div>
)

export const Comparison = ({
  title,
  caminoCardFeatures,
  secondCardTitle,
  secondCardFeatures,
  thirdCardTitle,
  thirdCardFeatures,
  mobileCardTitle,
  mobileCardFeatures,
}) => (
  <Section with-padding="true" with-background-decorators="true">
    <h2 className={styles.title} variant="micro">
      {title}
    </h2>

    <div className={styles.cards}>
      <div className={styles.caminoCard}>
        <LogoSVG height="52" />
        <List items={caminoCardFeatures?.map((feature) => ({ feature, Icon: CheckmarkSVG }))} component={Feature} />
      </div>

      <div className={styles.secondCard}>
        <h3>{secondCardTitle}</h3>
        <List items={secondCardFeatures?.map((feature) => ({ feature }))} component={Feature} />
      </div>

      <div className={styles.thirdCard}>
        <h3>{thirdCardTitle}</h3>
        <List items={thirdCardFeatures?.map((feature) => ({ feature }))} component={Feature} />
      </div>

      <div className={styles.mobileCard}>
        <h3>{mobileCardTitle}</h3>
        <List items={mobileCardFeatures?.map((feature) => ({ feature }))} component={Feature} />
      </div>
    </div>
  </Section>
)
