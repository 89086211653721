import React from 'react'

import ChevronDownSVG from '@/assets/svg/chevron-down.svg'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { useElementHeight } from '@/base/hooks/useElementHeight'

import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'

import * as styles from './styles.module.css'

export const Card = ({ id, icon, imageMobile, title, description, person, place, cta, isOpened }) => {
  const [ref, height] = useElementHeight()

  return (
    <div className={styles.card} style={{ '--height': height }}>
      <input type="checkbox" id={id} defaultChecked={isOpened} />

      <label htmlFor={id} className={styles.topBar}>
        <Image image={icon} />
        <h3>{title}</h3>
        <ChevronDownSVG width="40" />
      </label>

      <div className={styles.collapsible}>
        <Image image={imageMobile} imgStyle={{ objectFit: 'contain' }} className={styles.image} />

        <div ref={ref}>
          {getContentfulElementParagraph(description)}
          <div className="heading-person">{person}</div>
          <div className="heading-place uppercase">{place}</div>
        </div>

        <div className={styles.cta}>
          <Link {...cta} variant="button-default" />
        </div>
      </div>
    </div>
  )
}
