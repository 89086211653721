import React, { useMemo } from 'react'

import { useLanguageContext } from '@/base/context/LanguageContext'
import { decamelize } from '@/base/helpers/decamelize'

import { Section } from '@/components/modules/Section'
import { Table } from '@/components/modules/Table'
import { Link } from '@/components/elements/Link'

import * as styles from './styles.module.css'

export const LoanComparingTable = ({ title, rows, callToAction, note }) => {
  const { t } = useLanguageContext()

  const table = useMemo(() => {
    if (rows?.length > 0) {
      const [firstRow] = rows
      const headers = Object.keys(firstRow)
        .filter((key) => key !== 'featured')
        .map((key) => t([`loan_comparing_table.${decamelize(key)}`]))

      const data = rows.map((row) => ({ featured: row.featured, data: Object.values(row) }))

      return { headers, data }
    }
    return { headers: [], data: [] }
  }, [rows])

  return (
    <Section>
      <div className={styles.loan_comparing_table}>
        {title && <h2 className={styles.loan_comparing_table__title}>{title}</h2>}
        <Table headers={table.headers} data={table.data} />
        {callToAction?.label && (
          <div className={styles.loan_comparing_table__cta}>
            <Link {...callToAction} variant="button-default" />
            {note && <p className={styles.loan_comparing_table__cta__note}>{note}</p>}
          </div>
        )}
      </div>
    </Section>
  )
}
