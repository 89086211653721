import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'

import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'

import * as styles from './styles.module.css'

export const Card = ({ imageDesktop, title, description, person, place, cta }) => (
  <div className={styles.card}>
    <Image image={imageDesktop} />

    <div className={styles.content}>
      <h3>{title}</h3>
      {getContentfulElementParagraph(description)}

      <div className={`heading-person ${styles.person}`}>{person}</div>
      <div className={`heading-place uppercase ${styles.place}`}>{place}</div>

      <div className={styles.cta}>
        <Link {...cta} variant="button-default" />
      </div>
    </div>
  </div>
)
