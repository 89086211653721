import React from 'react'
import uniqid from 'uniqid'

import StarSVG from '@/assets/svg/star.svg'

import * as styles from './styles.module.css'

const STAR_SIZE = 52

export const Stars = ({ filledIn = 0, count = 5, starSize = STAR_SIZE }) => (
  <div className={styles.stars} style={{ '--star-size': starSize, '--star-count': count }}>
    {[...Array(count + 1).keys()].map((i) => {
      const id = uniqid()
      const filledInFactor = Math.min(filledIn, count) - i
      const isHighlighted = filledInFactor >= 0
      const isPartiallyFilledIn = isHighlighted && filledInFactor < 1

      return (
        <div
          key={id}
          className={styles.starWrapper}
          highlight={String(isHighlighted)}
          style={{
            '--star-number': i,
            '--star-filled-in-factor': filledInFactor,
          }}
        >
          <div className={styles.star} partial={String(isPartiallyFilledIn)}>
            <StarSVG height={starSize} width={starSize} />
          </div>
        </div>
      )
    })}
  </div>
)
