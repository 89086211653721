import React, { useMemo } from 'react'

import XSVG from '@/assets/svg/x.svg'
import CheckmarkSVG from '@/assets/svg/checkmark.svg'

import * as styles from './styles.module.css'

export const Table = ({ headers, data }) => {
  const renderHeaders = useMemo(() => {
    if (headers?.length > 0) {
      return (
        <thead>
          <tr>
            {headers.map((label, index) => {
              if (index === 0) {
                return <th key={label}></th>
              }
              return (
                <th key={label} className={styles.table__th}>
                  {label}
                </th>
              )
            })}
          </tr>
        </thead>
      )
    }
    return null
  }, [headers])

  const renderRows = useMemo(() => {
    if (data?.length > 0) {
      return (
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className={styles.table__tr} is-featured={row.featured ? 'true' : false}>
              {row.data.slice(0, headers?.length).map((item) => {
                let value = item
                if (item === 'yes') {
                  value = <CheckmarkSVG className={styles.table__icon} />
                }
                if (item === 'no') {
                  value = <XSVG className={styles.table__icon} />
                }
                return (
                  <td key={item} className={styles.table__td}>
                    {value}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      )
    }
    return null
  }, [data])

  return (
    <div className={styles.wrapper}>
      <table className={styles.table}>
        {renderHeaders}
        {renderRows}
      </table>
    </div>
  )
}
