import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { Image } from '@/components/elements/Image'
import { Link as LinkVariant } from '@/components/elements/Link/getLinkFromVariant'
import { List } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'
import * as styles from './styles.module.css'

export const Cta = ({ backgroundImage, buttons, body, title, videoId }) => {
  return (
    <div className={styles.wrapper}>
      <Section
        className={`${styles.section} ${backgroundImage ? styles.colorWhite : styles.colorPrimary}`}
        with-padding="true"
      >
        {title && <h2>{title}</h2>}
        {getContentfulElementParagraph(body)}
        {videoId && (
          <div className={styles.videoWrapper}>
            <iframe
              className={styles.video}
              src={`https://www.youtube.com/embed/${videoId}`}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        )}
        <List
          items={buttons}
          component={LinkVariant}
          className={styles.ctaWrapper}
          data-with-video={videoId && 'true'}
        />
      </Section>
      {backgroundImage && (
        <div className={styles.image}>
          <Image image={backgroundImage} loading="eager" objectFit="cover" objectPosition={'50%'} />
        </div>
      )}
    </div>
  )
}
