import React from 'react'

import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'
import { Logos } from '@/components/modules/Logos'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const Hero = ({ heading, description, cta, image, logosTitle, logos }) => (
  <Section with-padding="true" with-shadow="true">
    <div className={styles.columns}>
      <div className={styles.heading}>
        <h1 dangerouslySetInnerHTML={{ __html: heading }} />
        <p>{description}</p>
      </div>

      <div className={styles.cta}>
        <Link {...cta} variant="button-default" />
      </div>

      <div className={styles.logos}>
        <Logos title={logosTitle} logos={logos.map((image) => ({ image }))} />
      </div>

      <Image image={image} className={styles.image} />
    </div>
  </Section>
)
