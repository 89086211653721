import React from 'react'

import checkBoxPNG from '@/assets/images/checkbox-icon.png'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const Requirement = ({ featureTag }) => (
  <>
    <img src={checkBoxPNG} />
    {featureTag}
  </>
)

export const MainVariant08 = ({ label, title, body, image, featureTags, cta }) => (
  <Section with-arrow="true" with-padding="true" with-shadow="true">
    <div className={styles.heading}>
      {label && <p className={styles.label}>{label}</p>}
      {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
    </div>
    <div className={styles.columns}>
      <div className={styles.content}>
        {getContentfulElementParagraph(body)}
        {featureTags && featureTags.length > 0 && (
          <List items={featureTags.map((featureTag) => ({ featureTag }))} component={Requirement} />
        )}
      </div>
      <Image image={image} className={styles.image} />
      <div className={styles.cta}>
        <Link {...cta} variant="button-default" />
      </div>
    </div>
  </Section>
)
