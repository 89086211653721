export const getLeadFormSubmissionURL = ({ language_code, years_in_business, url_params }) => {
  const domain = 'https://www.caminofinancial.com/'

  let language_path = ''

  if (language_code === 'es') {
    language_path = 'es/'
  }

  let years_in_business_path = ''

  if (years_in_business === 'Less than 9 months') {
    years_in_business_path = 'pl/loan-financing-2/'
  }

  if (years_in_business === 'Between 9 and 12 months') {
    years_in_business_path = 'bl/loan-financing/'
  }

  if (years_in_business === null) {
    years_in_business_path = 'pl/loan-financing-2/'
  }

  const search_params = new URLSearchParams(url_params)

  return `${domain}${language_path}${years_in_business_path}?${search_params.toString()}`
}
