import React from 'react'

import { Section } from '@/components/modules/Section'
import { List } from '@/components/elements/List'
import { ReportCard } from '@/components/modules/ReportCard'

import * as styles from './styles.module.css'

export const Listing16 = ({ title, items }) => {
  return (
    <Section className={styles.container} with-padding="true">
      <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
      <List className={styles.reports_container} items={items} component={ReportCard} />
    </Section>
  )
}
