import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { Image } from '@/components/elements/Image'

import * as styles from './styles.module.css'

export const MainVariant06 = ({ label, title, image }) => {
  return (
    <div className={styles.container}>
      <div className={styles.columns}>
        <div className={styles.heading}>
          <p>{label}</p>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        {image && <Image image={image} className={styles.image} />}
      </div>
    </div>
  )
}
