import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'

import { Image } from '@/components/elements/Image'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const MainVariant14 = ({ image, body, label }) => (
  <Section className={styles.section}>
    <div className={styles.wrapper}>
      <Image image={image} imgStyle={{ objectFit: 'contain' }} />

      <div className={styles.content}>
        {getContentfulElementParagraph(body)}
        <p className={styles.label}>{label}</p>
      </div>
    </div>
  </Section>
)
