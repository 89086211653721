export const getLoanType = ({ type, valueTimeInBusiness }) => {
  /* loan_type is "personal" for SOLO loans or while time_in_business is "Less than 9 months" */
  const isPersonalType = valueTimeInBusiness?.value === 'Less than 9 months' || type === 'personal'

  if (isPersonalType) {
    return 'personal'
  }

  return 'business'
}
