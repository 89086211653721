import React from 'react'

import backgroundPNG from '@/assets/images/fondamental-fairness-background.png'
import { Image } from '@/components/elements/Image'
import { List as ElementList } from '@/components/elements/List'
import { Link } from '@/components/elements/Link'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const ListingItem = ({ title, description, icon, url }) => (
  <Link to={url} className={styles.listItem}>
    <div className={styles.circle}>
      <Image image={icon} />
    </div>
    <p dangerouslySetInnerHTML={{ __html: title }} />
    <p className={styles.description}>{description}</p>
  </Link>
)

export const Listing01 = ({ title, items }) => (
  <Section with-padding="true">
    <h2 className={styles.heading}>{title}</h2>
    <ElementList items={items} component={ListingItem} className={styles.list} />
    <div className={styles.image}>
      <Image image={{ src: backgroundPNG }} loading="eager" objectFit="cover" objectPosition={'50%'} />
    </div>
  </Section>
)
