import React from 'react'

import Logo1 from '@/assets/images/Logo_certification1.png'
import Logo2 from '@/assets/images/Logo_certification2.png'
import Logo3 from '@/assets/images/Logo_certification3.png'
import Logo4 from '@/assets/images/Logo_certification4.png'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { useLanguageContext } from '@/base/context/LanguageContext'

import { Image } from '@/components/elements/Image'
import { List } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const Statistic = ({ title, description }) => (
  <div className={styles.statistic}>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
)

export const Listing10 = ({ title, body, items, image }) => {
  const { t } = useLanguageContext()

  return (
    <Section className={styles.section}>
      <h2>{title}</h2>

      {getContentfulElementParagraph(body)}

      <List items={items} component={Statistic} className={styles.statistics} />

      <div className={styles.certifications}>
        <h3>{t`accreditation`}</h3>
        <ul>
          <li>
            <Image image={{ src: Logo1 }} />
          </li>
          <li>
            <Image image={{ src: Logo2 }} />
          </li>
          <li>
            <Image image={{ src: Logo3 }} />
          </li>
          <li>
            <Image image={{ src: Logo4 }} />
          </li>
        </ul>
      </div>

      <Image image={image} className={styles.image} />
    </Section>
  )
}
