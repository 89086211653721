import React from 'react'

import * as styles from './styles.module.css'

const clamp = (min, max) => (number) => Math.max(min, Math.min(number, max))

const levelClamp = clamp(1, 6)

export const Heading = ({ level, children, ...rest }) => {
  const HTMLElement = `h${levelClamp(level)}`

  return (
    <div className={styles.wrapper} {...rest}>
      <HTMLElement className={styles.heading}>{children}</HTMLElement>
    </div>
  )
}
