import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'

import { Image } from '@/components/elements/Image'
import { List } from '@/components/elements/List'
import { NoHardInquiryCTAButton } from '@/components/modules/CTA/NoHardInquiryCTAButton'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const ListItem = ({ title, description }) => (
  <div className={styles.listItem}>
    <p>{description}</p>
    <h3>{title}</h3>
  </div>
)

export const Benefits = ({ title, body, image, cta, loanDetails }) => (
  <Section with-padding="true" with-background-decorators="true">
    <div className={styles.wrapper}>
      <div className={styles.image}>
        <Image image={image} />
      </div>

      <h2>{title}</h2>

      {getContentfulElementParagraph(body)}

      <div className={styles.cta}>
        <NoHardInquiryCTAButton {...cta} />
      </div>

      <List items={loanDetails} component={ListItem} className={styles.list} />
    </div>
  </Section>
)
