import React from 'react'

export const useWindowListener = (type, listenerFunction) => {
  React.useEffect(() => {
    listenerFunction()

    window.addEventListener(type, listenerFunction)

    return () => window.removeEventListener(type, listenerFunction)
  }, [type, listenerFunction])
}
