import React from 'react'

import { Image } from '@/components/elements/Image'
import { List } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const Logos = ({ label, logos }) => (
  <Section className={styles.section}>
    <p>{label}</p>
    <List
      items={logos.map((image) => ({ image }))}
      component={Image}
      itemProps={{ className: styles.image, objectFit: 'contain' }}
      className={styles.logos}
    />
  </Section>
)
