import { useState, useEffect } from 'react'

import FORMULAS from '@/base/helpers/formulas'

export const useCalculator = (loanAmountRange, paymentTermRange, monthlyInterestRateRange, type) => {
  const updateMonthlyPayment = () =>
    setMonthlyPayment(FORMULAS.monthlyPayment(loanAmount, monthlyInterestRate / 100, paymentTerm))

  const updateClosingFee = () => setClosingFee(FORMULAS.fee(loanAmount, type))
  const updateTotalInterestPaid = () => setTotalInterestPaid(monthlyPayment * paymentTerm - loanAmount)
  const updateTotalCostOfTheLoan = () => setTotalCostOfTheLoan(totalInterestPaid + closingFee)
  const updateAPR = () => setApr(FORMULAS.apr(paymentTerm, monthlyPayment, loanAmount, closingFee))

  const [loanAmount, setLoanAmount] = useState(loanAmountRange[type].defaultValue)
  const [paymentTerm, setPaymentTerm] = useState(paymentTermRange[type].defaultValue)
  const [monthlyInterestRate, setMonthlyInterestRate] = useState(monthlyInterestRateRange[type].defaultValue)

  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [closingFee, setClosingFee] = useState(0)
  const [apr, setApr] = useState(0)
  const [totalInterestPaid, setTotalInterestPaid] = useState(0)
  const [totalCostOfTheLoan, setTotalCostOfTheLoan] = useState(0)

  useEffect(() => {
    updateMonthlyPayment()
  }, [])

  useEffect(() => {
    updateMonthlyPayment()
  }, [loanAmount, paymentTerm, monthlyInterestRate])

  useEffect(() => {
    updateClosingFee()
  }, [loanAmount])

  useEffect(() => {
    updateTotalInterestPaid()
  }, [loanAmount, paymentTerm, monthlyPayment])

  useEffect(() => {
    updateTotalCostOfTheLoan()
  }, [totalInterestPaid, closingFee])

  useEffect(() => {
    updateAPR()
  }, [loanAmount, monthlyPayment, paymentTerm, closingFee])

  return {
    setLoanAmount,
    setPaymentTerm,
    setMonthlyInterestRate,
    monthlyPayment,
    closingFee,
    apr,
    totalInterestPaid,
    totalCostOfTheLoan,
    loanAmount,
    paymentTerm,
    monthlyInterestRate,
  }
}
