import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'

import { useCalculator } from '@/base/hooks/useCalculator'
import { useLanguageContext } from '@/base/context/LanguageContext'
import { useToggle } from '@/base/hooks/useToggle'
import { toCurrency, toMonths, toPercent } from '@/base/helpers/format'

import { Link } from '@/components/elements/Link'
import { RichText } from '@/components/elements/RichText'

import { Range } from '@/components/modules/Range'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const LOAN_AMOUNT = {
  business: {
    step: 125,
    min: 1000,
    max: 400000,
    defaultValue: 34000,
  },
}

const PAYMENT_TERM = {
  business: {
    step: 6,
    min: 18,
    max: 60,
    defaultValue: 36,
  },
}

const MONTHLY_INTEREST_RATE = {
  business: {
    step: 0.25,
    min: 1,
    max: 3.5,
    defaultValue: 2.25,
  },
}

const Row = ({ label, value }) => (
  <div className={styles.row}>
    <span className={styles.rowLabel}>{label}</span>
    <span className={styles.rowValue}>{toCurrency(Math.ceil(value))}</span>
  </div>
)

export const LoanCalculatorWithRichTextPrimary = ({ body, calculatorType, cta, upperTitle, title }) => {
  const { t } = useLanguageContext()
  const {
    setLoanAmount,
    setPaymentTerm,
    setMonthlyInterestRate,
    monthlyPayment,
    closingFee,
    totalInterestPaid,
    totalCostOfTheLoan,
  } = useCalculator(LOAN_AMOUNT, PAYMENT_TERM, MONTHLY_INTEREST_RATE, calculatorType[0])

  const [isMoreInfoVisible, _, componentToggleViewMoreLess] = useToggle()

  return (
    <Section with-background-gradient="true">
      <div className={styles.wrapper}>
        <p className={styles.upperTitle}>{upperTitle}</p>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.content}>
          <div className={styles.calculator}>
            <div className={styles.calculatorWrapper}>
              <div className={styles.rangeWrapper}>
                <Range
                  secondary
                  title={t`calculator.loan_amount`}
                  unitFormatter={toCurrency}
                  onValueUpdate={setLoanAmount}
                  {...LOAN_AMOUNT[calculatorType[0]]}
                />
                <Range
                  secondary
                  title={t`calculator.payment_term`}
                  unitFormatter={toMonths(t)}
                  onValueUpdate={setPaymentTerm}
                  {...PAYMENT_TERM[calculatorType[0]]}
                />
                <Range
                  secondary
                  title={t`calculator.monthly_interest_rate`}
                  unitFormatter={toPercent}
                  onValueUpdate={setMonthlyInterestRate}
                  {...MONTHLY_INTEREST_RATE[calculatorType[0]]}
                />
              </div>
              <Row label={t`calculator.monthly_payment`} value={monthlyPayment} />
              <Row label={t`calculator.total_cost_of_the_loan`} value={totalCostOfTheLoan} />
              {isMoreInfoVisible && (
                <>
                  <Row label={t`calculator.funding_fee`} value={0} />
                  <Row label={t`calculator.documentation_fee`} value={0} />
                  <Row label={t`calculator.closing_fee`} value={closingFee} />
                  <Row label={t`calculator.total_interest_paid`} value={totalInterestPaid} />
                </>
              )}
              <div className={styles.toggler}>{componentToggleViewMoreLess}</div>
              {cta && (
                <div className={styles.cta}>
                  <Link {...cta} variant="button-micro" />
                </div>
              )}
            </div>
          </div>
          {body && (
            <div className={styles.richTextWrapper}>
              <RichText
                data={body}
                options={{
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (_node, children) => <p className={styles.richParagraph}>{children}</p>,
                    [BLOCKS.HEADING_4]: (_node, children) => <h4 className={styles.richHeading4}>{children}</h4>,
                    [BLOCKS.UL_LIST]: (_node, chilredn) => <ul className={styles.richList}>{chilredn}</ul>,
                    [BLOCKS.LIST_ITEM]: (_node, chilredn) => <li className={styles.richListItem}>{chilredn}</li>,
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}
