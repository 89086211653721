import React from 'react'

import { Heading } from '@/components/elements/Heading'
import { List as ElementList } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const ListingItem = ({ title, description }) => (
  <div className={styles.listItem}>
    <div className={styles.circle}>
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </div>
    <p dangerouslySetInnerHTML={{ __html: description }} />
  </div>
)

export const Listing03 = ({ title, items }) => (
  <Section with-padding="true">
    <Heading level="2" with-border="true">
      {title}
    </Heading>
    <ElementList items={items} component={ListingItem} className={styles.list} />
  </Section>
)
