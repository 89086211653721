import React from 'react'
import uniqid from 'uniqid'

import ChevronDownSVG from '@/assets/svg/chevron-down.svg'
import worldPNG from '@/assets/icons/world.png'

import { useLanguageContext } from '@/base/context/LanguageContext'
import { getLanguageConfigByLanguageCode } from '@/base/language/getLanguageConfig'

import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'

import * as styles from './styles.module.css'

const LanguageSelectItem = ({ alternateLanguagePagePaths, current_language_code, language_code, ...props }) => {
  const { language_name } = getLanguageConfigByLanguageCode(language_code)

  return (
    <Link
      to={alternateLanguagePagePaths[language_code]}
      is-active={String(current_language_code === language_code)}
      className={styles.link}
      {...props}
    >
      <span className={styles.upperCase}>{language_code}</span> -{' '}
      <span className={styles.capitalizedCase}>{language_name}</span>
    </Link>
  )
}

export const LinkLanguageSelect = (props) => {
  const id = uniqid()

  const { alternateLanguagePagePaths, language_code } = useLanguageContext()

  return (
    <div className={styles.wrapper}>
      <input id={id} type="checkbox" />

      <label htmlFor={id} className={styles.select}>
        {/* TODO: replace with an SVG file */}
        <img src={worldPNG} />
        <span>{language_code}</span>
        <ChevronDownSVG height="16" />
      </label>

      <List
        items={Object.keys(alternateLanguagePagePaths).map((language_code) => ({ language_code }))}
        component={LanguageSelectItem}
        itemProps={{ alternateLanguagePagePaths, current_language_code: language_code, ...props }}
        className={styles.list}
      />
    </div>
  )
}
