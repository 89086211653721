import React from 'react'

import { useLanguageContext } from '@/base/context/LanguageContext'
import { toCurrency, toMonths, toPercent } from '@/base/helpers/format'
import { useCalculator } from '@/base/hooks/useCalculator'
import { useToggle } from '@/base/hooks/useToggle'

import { Range } from '@/components/modules/Range'
import { NoHardInquiryCTAButton } from '@/components/modules/CTA/NoHardInquiryCTAButton'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const LOAN_AMOUNT = {
  business: {
    step: 125,
    min: 5000,
    max: 75000,
    defaultValue: 12000,
  },
  personal: {
    step: 125,
    min: 1500,
    max: 7500,
    defaultValue: 3500,
  },
}

const PAYMENT_TERM = {
  business: {
    min: 24,
    max: 36,
    defaultValue: 26,
  },
  personal: {
    min: 24,
    max: 36,
    defaultValue: 20,
  },
}

const MONTHLY_INTEREST_RATE = {
  business: {
    step: 0.25,
    min: 2.25,
    max: 3.75,
    defaultValue: 3,
  },
  personal: {
    step: 0.25,
    min: 2,
    max: 3,
    defaultValue: 2.25,
  },
}

const Row = ({ label, value }) => (
  <div className={styles.row}>
    <span>{label}</span>
    <span>{toCurrency(Math.ceil(value))}</span>
  </div>
)

export const LoanCalculatorTertiary = ({ title, cta, type = 'business', calculatorType }) => {
  const { t } = useLanguageContext()
  const {
    setLoanAmount,
    setPaymentTerm,
    setMonthlyInterestRate,
    monthlyPayment,
    closingFee,
    apr,
    totalInterestPaid,
    totalCostOfTheLoan,
  } = useCalculator(LOAN_AMOUNT, PAYMENT_TERM, MONTHLY_INTEREST_RATE, calculatorType || type)

  const [isMoreInfoVisible, _, componentToggleViewMoreLess] = useToggle()

  return (
    <Section with-padding="true" with-background-gradient="true">
      <h2 className={styles.title} variant="micro">
        {title}
      </h2>

      <div className={styles.calculator} style={{ '--rows': isMoreInfoVisible ? 4 : 3 }}>
        <Range
          title={t`calculator.loan_amount`}
          unitFormatter={toCurrency}
          onValueUpdate={setLoanAmount}
          {...LOAN_AMOUNT[calculatorType || type]}
        />

        <Range
          title={t`calculator.payment_term`}
          unitFormatter={toMonths(t)}
          onValueUpdate={setPaymentTerm}
          {...PAYMENT_TERM[calculatorType || type]}
        />

        {isMoreInfoVisible && (
          <Range
            title={t`calculator.monthly_interest_rate`}
            unitFormatter={toPercent}
            onValueUpdate={setMonthlyInterestRate}
            {...MONTHLY_INTEREST_RATE[calculatorType || type]}
          />
        )}

        <div className={styles.result}>
          <h3>{t`calculator.monthly_payment`}</h3>
          <div className={styles.total}>{toCurrency(monthlyPayment)}</div>
          {type === 'personal' ||
            (calculatorType === 'personal' && <div className={styles.apr}>APR: {toPercent(apr)}</div>)}
        </div>

        <div className={styles.summary}>
          <Row label={t`calculator.total_cost_of_the_loan`} value={totalCostOfTheLoan} />

          {componentToggleViewMoreLess}

          {isMoreInfoVisible && (
            <>
              <Row label={t`calculator.closing_fee`} value={closingFee} />
              <Row label={t`calculator.total_interest_paid`} value={totalInterestPaid} />
            </>
          )}
        </div>

        <div className={styles.cta}>
          <NoHardInquiryCTAButton {...cta} />
        </div>
      </div>
    </Section>
  )
}
