import React from 'react'
import uniqid from 'uniqid'
import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel'

import ChevronDownSVG from '@/assets/svg/chevron-down.svg'

import { transformArrayIntoGroups } from '@/base/helpers/transformArrayIntoGroups'

import { List } from '@/components/elements/List'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import * as styles from './styles.module.css'

const getArrow = (className) => (onClick, isVisible) =>
  isVisible && <ChevronDownSVG width="64" onClick={onClick} className={className} />

export const Carousel = ({
  items = [],
  component: Component,
  itemsInGroup = 3,
  withArrows = true,
  indicatorSize = 24,
}) => (
  <div
    className={styles.carousel}
    style={{ '--items-in-group': itemsInGroup, '--indicator-size': `${indicatorSize}px` }}
  >
    <ReactResponsiveCarousel
      preventMovementUntilSwipeScrollTolerance
      showStatus={false}
      showThumbs={false}
      {...(withArrows && { renderArrowPrev: getArrow(styles.arrowPrev), renderArrowNext: getArrow(styles.arrowNext) })}
    >
      {transformArrayIntoGroups(items, itemsInGroup).map((group) => (
        <List key={uniqid()} items={group} component={Component} className={styles.group} />
      ))}
    </ReactResponsiveCarousel>
  </div>
)
