import React from 'react'

import { Image } from '@/components/elements/Image'
import { List as ElementList } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'
import { Link } from '@/components/elements/Link'
import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'

import * as styles from './styles.module.css'

const ListingItem = ({ icon, title, description }) => (
  <div className={styles.listItem}>
    <div>
      <div className={styles.title}>
        <Image image={icon} />
        <h3>{title}</h3>
      </div>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </div>
)

export const Listing14 = ({ label, title, body, items, cta }) => (
  <Section with-padding="true" className={styles.section}>
    <p className={styles.label}>{label}</p>
    <h2 dangerouslySetInnerHTML={{ __html: title }} />
    {getContentfulElementParagraph(body)}
    <ElementList items={items} component={ListingItem} className={styles.list} />
    <div className={styles.cta}>
      <Link {...cta} variant="button-default" />
    </div>
  </Section>
)
