import React from 'react'

import FacebookSVG from '@/assets/svg/facebook.svg'
import GoogleSVG from '@/assets/svg/google.svg'
import TrustPilotSVG from '@/assets/svg/trust-pilot.svg'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { useLanguageContext } from '@/base/context/LanguageContext'
import { useElementHeight } from '@/base/hooks/useElementHeight'

import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'
import { Stars } from '@/components/elements/Stars'

import * as styles from './styles.module.css'

const sources = {
  Facebook: { logo: FacebookSVG, color: '#3B5980' },
  Google: { logo: GoogleSVG, color: '#2880E0' },
  'Trust Pilot': { logo: TrustPilotSVG, color: '#2AB580' },
}

const getLogo = (source) => {
  const Logo = sources[source].logo
  return <Logo height="48" width="48" />
}

export const ReviewCard = ({
  id,
  avatar,
  person,
  place,
  rating,
  date,
  reviewShort,
  reviewLong,
  link,
  source,
  variant,
}) => {
  const { t } = useLanguageContext()

  const [ref, height] = useElementHeight()

  return (
    <div className={styles.card}>
      <div className={styles.header} variant={variant}>
        <div className={styles.avatar}>
          <Image image={avatar} />
        </div>

        <div>
          <h3 className="heading-person" variant={variant}>
            {person}
          </h3>
          {place && <p className="heading-place uppercase">{place}</p>}
        </div>

        <div className={styles.rating} variant={variant}>
          <Stars filledIn={rating} starSize={variant === 'micro' ? 18 : 24} />
          <p>{date}</p>
        </div>
      </div>

      <div className={styles.content} style={{ '--height': height }}>
        {getContentfulElementParagraph(reviewShort)}
        {reviewLong && (
          <>
            <input type="checkbox" id={id} />
            <label htmlFor={id}>{t`read_more`}</label>
            <div className={styles.contentLong}>
              <div ref={ref}>{getContentfulElementParagraph(reviewLong)}</div>
            </div>
          </>
        )}
      </div>

      {source && (
        <div className={styles.source}>
          <Link to={link?.url}>{getLogo(source)} </Link>
          <p>
            {t`posted_on`}
            <br />
            <Link to={link?.url}>
              <span style={{ color: sources[source].color }}>{source}</span>
            </Link>
          </p>
        </div>
      )}
    </div>
  )
}
