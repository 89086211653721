import React from 'react'
import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const ThreeColumnWithImage = ({ title, image, list, cta }) => {
  const ListItem = ({ title, description, ...props }) => {
    if (!title && !description)
      return <Image image={props} imgStyle={{ objectFit: 'contain' }} className={styles.image} />

    return (
      <div className={styles.listItem}>
        <h3 className={styles.listItemTitle}>{title}</h3>
        <p className={styles.listItemDescription}>{description}</p>
      </div>
    )
  }
  return (
    <Section with-padding="true" with-arrow="true" with-background-decorators="true">
      <h2 className={styles.title}>{title}</h2>
      {list.length && <List items={[image, ...list]} component={ListItem} className={styles.listWrapper} />}
      {cta && (
        <div className={styles.cta}>
          <Link {...cta} variant="button-default" />
        </div>
      )}
    </Section>
  )
}
