import React, { useMemo } from 'react'

import FORMULAS from '@/base/helpers/formulas'

import { useLanguageContext } from '@/base/context/LanguageContext'
import { toSecondDigitCurrency, toPercent } from '@/base/helpers/format'

import { Button } from '@/components/elements/Button'
import { Link } from '@/components/elements/Link'

import * as styles from './styles.module.css'

const Row = ({ label, value, type = 'primary' }) => {
  return (
    <div className={styles.result} type={type}>
      <p className={styles.label}>{label}</p>
      <p className={styles.value}>{value}</p>
    </div>
  )
}

export const CalculatorResults = ({ money, period, interestRate, reset, cta }) => {
  const { t } = useLanguageContext()
  const closingFee = useMemo(() => (money * 6.99) / 100, [money])
  const monthlyPayment = useMemo(() =>
    FORMULAS.monthlyPayment(money, interestRate.value / 100, period.value, [money, period.value, interestRate.value])
  )
  const totalInterestPaid = useMemo(() => monthlyPayment * period.value - money)
  const totalCostOfTheLoan = useMemo(() => totalInterestPaid + closingFee, [closingFee, totalInterestPaid])

  return (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.title}>{t`results.loan_terms`}</h3>
        <Row label={t`results.loan_amount`} value={toSecondDigitCurrency(money)} />
        <Row label={t`results.number_of_payments`} value={period.value} />
        <Row label={t`results.payment_frequency`} value={'Monthly'} />
        <Row label={t`results.monthly_intrest_rate`} value={toPercent(interestRate.value)} />
        <Row label={t`results.closing_fee`} value={toSecondDigitCurrency(closingFee)} />
        <Row label={t`results.documentation_fee`} value={toSecondDigitCurrency(0)} />
      </div>

      <div>
        <h3 className={styles.title}>{t`results.calculator_results`}</h3>
        <Row label={t`results.total_interest_paid`} value={toSecondDigitCurrency(totalInterestPaid)} />
        <Row label={t`results.closing_fee`} value={toSecondDigitCurrency(closingFee)} />
        <Row label={t`results.funding_fee`} value={toSecondDigitCurrency(0)} />
        <Row label={t`results.documentation_fee`} value={toSecondDigitCurrency(0)} />
        <Row label={t`results.cost_of_the_loan`} value={toSecondDigitCurrency(totalCostOfTheLoan)} type="secondary" />
        <Row label={t`results.monthly_payment`} value={toSecondDigitCurrency(monthlyPayment)} type="secondary" />
      </div>
      <Link variant="button-default" theme="button-white" {...cta} />
      <Button variant="border" onClick={reset}>{t`results.reset`}</Button>
    </div>
  )
}
