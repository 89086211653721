import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const MainVariant12 = ({ title, body, image, cta }) => (
  <Section with-padding="true" with-shadow="true" with-light-background="true" className={styles.wrapper}>
    <div className={styles.columns}>
      <div className={styles.heading}>
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        {getContentfulElementParagraph(body)}
      </div>
      {cta && (
        <div className={styles.cta}>
          <Link {...cta} variant="button-default" />
        </div>
      )}
    </div>

    {image && (
      <div className={styles.imageContainer}>
        <Image image={image} className={styles.image} />
      </div>
    )}
  </Section>
)
