import { getLeadFormId } from '@/base/helpers/getLeadFormId'
import {
  getUserAgent,
  getUserIP,
  getUserLeadGeo,
  getGoogleAnalyticsClientID,
  getSegmentAnonymousID,
} from '@/base/helpers/getUserData'
import { getLeadFormParamsFromSearchQuery } from '@/base/helpers/getLeadFormParamsFromSearchQuery'
import { getLoanType } from '@/base/helpers/getLoanType'

export const submitLeadForm = async ({
  valueFirstName,
  valueLastName,
  valueEmail,
  valuePhone,
  valueTimeInBusiness,
  valueConsent,

  type,
  language_code,
  location,
  utm_params,
}) => {
  const loan_type = getLoanType({ type, valueTimeInBusiness })

  const params = getLeadFormParamsFromSearchQuery(utm_params)

  const ip_address = await getUserIP()

  const lead_geo = await getUserLeadGeo({ ip_address })

  const leadform_id = getLeadFormId({ type, language_code })
  const google_analytics_client_id = getGoogleAnalyticsClientID()
  const current_time = new Date().toISOString()

  const form_values = {
    first_name: valueFirstName,
    last_name: valueLastName,
    email: valueEmail,
    years_in_business: loan_type === 'business' ? valueTimeInBusiness?.value : undefined,
  }

  const id_values = {
    google_analytics_client_id,
    gclid: params.gclid,
    fbclid: params.fbclid,
    msclkid: params.msclkid,
  }

  const utm_values = {
    utm_source: params.utm_source,
    utm_campaign: params.utm_campaign,
    utm_medium: params.utm_medium,
    utm_content: params.utm_content,
    utm_term: params.utm_term,
  }

  const response = await fetch('/.netlify/functions/get-a-loan', {
    method: 'POST',
    body: JSON.stringify({
      ...form_values,
      phone_number: valuePhone, // the field is called "phone_number" in url_params
      checkbox: valueConsent ? 'on' : 'off', // it should be provided, even if the field is required
      ...id_values,

      created_at: current_time,
      updated_at: current_time,
      page_url: location?.href,
      context_page_url: location?.href,
      context_page_referrer: document?.referrer,

      ip_address,
      lead_geo,
      language: language_code,
      user_agent: getUserAgent({ navigator }),
      leadform_id,
      loan_type,
      anonymous_id: getSegmentAnonymousID(),

      /* utm params */
      lead_source: utm_values.utm_source,
      ...utm_values,
    }),
  })

  const all_url_params = {
    ...form_values,
    contact_number: valuePhone,
    ...id_values,
    ...utm_values,

    lang: language_code,
    loan_type,
    leadform_id,
  }

  // filter out "null" and "undefined" values
  const url_params = Object.keys(all_url_params).reduce(
    (acc, key) => (!all_url_params[key] ? acc : { ...acc, [key]: all_url_params[key] }),
    {}
  )

  return { response, url_params }
}
