import React from 'react'

import { Image } from '@/components/elements/Image'
import { List as ElementList } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const ListingItem = ({ title, description, icon, imageIcon }) => (
  <div className={styles.listItem}>
    {icon && (
      <div className={styles.circle}>
        <Image image={icon} />
      </div>
    )}
    {imageIcon && <Image image={imageIcon} />}
    <p className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
    <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
  </div>
)

export const Listing05 = ({ title, items }) => (
  <Section with-padding="true" className={styles.section} with-light-blue-background="true">
    <h2 dangerouslySetInnerHTML={{ __html: title }} />
    <ElementList items={items} component={ListingItem} className={styles.list} />
  </Section>
)
