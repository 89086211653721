import React from 'react'

import { useIsMobile } from '@/base/hooks/useIsMobile'

import { List } from '@/components/elements/List'
import { Card as CardCollapsible } from '@/components/modules/Card/Collapsible'
import { Card as CardDefault } from '@/components/modules/Card/Default'
import { Carousel } from '@/components/modules/Carousel'
import { CTASubsection } from '@/components/modules/CTASubsection'
import { Section } from '@/components/modules/Section'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import * as styles from './styles.module.css'

export const Cards = ({ title, cards, ctaTitle, ctaDescription, cta }) => {
  const isMobile = useIsMobile()

  return (
    <Section with-arrow="true" with-background="true" with-padding="true" with-shadow="true">
      <h2 className={styles.title}>{title}</h2>

      {isMobile ? (
        <List items={cards} component={CardCollapsible} className={styles.cards} />
      ) : (
        <Carousel items={cards} component={CardDefault} />
      )}

      <CTASubsection {...{ ctaTitle, ctaDescription, cta }} />
    </Section>
  )
}
