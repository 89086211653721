import React from 'react'

import RequirementsSVG from '@/assets/svg/requirements.svg'

import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const Requirement = ({ featureTag }) => (
  <>
    <RequirementsSVG height="64" width="64" />
    {featureTag}
  </>
)

export const MainVariant07 = ({ title, image, featureTags, cta }) => (
  <Section with-arrow="true" with-padding="true" with-shadow="true">
    <div className={styles.columns}>
      <Image image={image} className={styles.image} />

      <h2 className={styles.title}>{title}</h2>

      {featureTags && featureTags.length > 0 && (
        <List
          items={featureTags.map((featureTag) => ({ featureTag }))}
          component={Requirement}
          className={styles.featureTags}
        />
      )}

      <div className={styles.cta}>
        <Link {...cta} variant="button-default" />
      </div>
    </div>
  </Section>
)
