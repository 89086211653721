export const toSecondDigitCurrency = (number) => `$${number.toFixed(2)}`

export const toCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format

export const toMonths = (t) => (number) => `${number} ${t`months`}`

export const toPercent = (number) => `${number} %`

export const toNumber = (string) => string.replace(/\D/g, '')

export const toPhoneNumber = (string) => {
  const cleaned = `${string}`.replace(/\D/g, '')

  const match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/)

  if (!match) {
    return string
  }

  return (match[2] ? '(' : '') + match[1] + (match[2] ? ') ' : '') + match[2] + (match[3] ? '-' : '') + match[3]
}
