// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--Q1C0y";
export var section = "styles-module--section--9qbRF";
export var container = "styles-module--container--je-XC";
export var ctaWrapper = "styles-module--ctaWrapper--RNFzU";
export var subTitle = "styles-module--subTitle--JWGmu";
export var largeTitle = "styles-module--largeTitle--OPDPh";
export var fullWidth = "styles-module--fullWidth--SEBTx";
export var ctaTransparent = "styles-module--ctaTransparent---UrMV";
export var ctaSecondary = "styles-module--ctaSecondary--T0Yjj";
export var shareLinks = "styles-module--shareLinks--UVvw4";
export var image = "styles-module--image--svsUD";