import React from 'react'

import { toNumber, toPhoneNumber } from '@/base/helpers/format'

import * as styles from './styles.module.css'

export const InputPhone = ({ value, onChange, ...props }) => {
  const onKeyPress = (event) => {
    if (!(event.key in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9])) {
      event.preventDefault()
    }
  }

  const onChanged = (e) => {
    const numberValue = toNumber(e.target.value)

    e.target.value = toPhoneNumber(numberValue)

    onChange?.({ target: { value: toPhoneNumber(numberValue) } })
  }

  return (
    <input
      type="tel"
      inputMode="tel"
      maxLength="14"
      pattern="\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}"
      className={styles.input}
      value={value}
      data-value={value}
      onChange={onChanged}
      onKeyPress={onKeyPress}
      {...props}
    />
  )
}
