import React from 'react'

import { BLOCKS } from '@contentful/rich-text-types'

import { Section } from '@/components/modules/Section'
import { List } from '@/components/elements/List'
import { RichText } from '@/components/elements/RichText'
import { ReportCard } from '@/components/modules/ReportCard'

import * as styles from './styles.module.css'

export const ReportsList = ({ reportsListTitle: title, reports }) => {
  function renderRichText(data) {
    if (!data) {
      return null
    }

    return (
      <RichText
        data={data}
        options={{
          renderNode: {
            [BLOCKS.HEADING_2]: (_node, children) => <h2 className={styles.title}>{children}</h2>,
          },
        }}
      />
    )
  }

  return (
    <Section className={styles.container} with-padding="true">
      {renderRichText(title)}
      <List className={styles.reports_container} items={reports} component={ReportCard} />
    </Section>
  )
}
