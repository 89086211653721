import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { Link } from '@/components/elements/Link'

import * as styles from './styles.module.css'

export const ReportCard = ({ title, description: summary, cta }) => {
  return (
    <div className={styles.wrapper}>
      {title && <h5 className={styles.title}>{title}</h5>}
      {summary && (
        <>
          <p className={styles.summary_title}>Summary:</p>
          <p className={styles.summary}>{getContentfulElementParagraph(summary)}</p>
        </>
      )}
      {cta && (
        <div className={styles.cta_wrapper}>
          <Link {...cta} variant="button-default" />
        </div>
      )}
    </div>
  )
}
