import React from 'react'

import { BLOCKS } from '@contentful/rich-text-types'
import { Section } from '@/components/modules/Section'
import { RichText } from '@/components/elements/RichText'
import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'

import * as styles from './styles.module.css'

export const TwoColumns = ({ title, twoColumnsDescription: description, image, reversed, callToAction }) => {
  function renderRichText(data) {
    if (!data) {
      return null
    }

    return (
      <RichText
        data={data}
        options={{
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => <p className={styles.twocolumns__description}>{children}</p>,
          },
        }}
      />
    )
  }

  return (
    <Section is-fluid={reversed ? '' : 'true'}>
      <div className={styles.twocolumns__wrapper} is-reversed={reversed ? 'true' : ''}>
        {image && (
          <Image className={styles.twocolumns__image} image={image} alt={image.alt || title} objectFit="contain" />
        )}
        <div className={styles.twocolumns__text}>
          {title && <h2 className={styles.twocolumns__title}>{title}</h2>}
          {renderRichText(description)}
          {callToAction?.label && (
            <div className={styles.twocolumns__cta}>
              <Link {...callToAction} variant="button-default" />
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}
