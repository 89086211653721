import React from 'react'

import { List } from '@/components/elements/List'
import { SocialLink } from '@/components/elements/SocialLink'
import Facebook from '@/assets/svg/facebook-icon.svg'
import Twitter from '@/assets/svg/twitter-icon.svg'
import LinkedIn from '@/assets/svg/linkedin-icon.svg'
import * as styles from './styles.module.css'

export const ShareIcons = ({ url }) => {
  const socialLinks = [
    {
      icon: { SVG: Twitter },
      link: { url: `https://twitter.com/share?url=${url}` },
    },
    {
      icon: { SVG: Facebook },
      link: { url: `https://www.facebook.com/sharer.php?u=${url}` },
    },
    {
      icon: { SVG: LinkedIn },
      link: { url: `https://www.linkedin.com/shareArticle?mini=true&url=${url}` },
    },
  ]
  return <List items={socialLinks} component={SocialLink} className={styles.socialLinks} />
}
