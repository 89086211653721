import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'

import { Link } from '@/components/elements/Link'

import * as styles from './styles.module.css'

export const CTASubsection = ({ ctaTitle, ctaDescription, cta }) => (
  <div className={styles.subsection}>
    <h3>{ctaTitle}</h3>
    {getContentfulElementParagraph(ctaDescription)}
    <div className={styles.cta}>
      <Link {...cta} variant="button-border" />
    </div>
  </div>
)
