import React from 'react'

import { useWindowListener } from '@/base/hooks/useWindowListener'

export const useElementHeight = () => {
  const ref = React.useRef()

  const [height, setHeight] = React.useState(0)

  const recalculateHeight = () => {
    setHeight(ref?.current?.offsetHeight || 0)
  }

  useWindowListener('resize', recalculateHeight)

  return [ref, height]
}
