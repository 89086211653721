export const getLeadFormId = ({ type, language_code }) => {
  if (language_code === 'en') {
    if (type === 'business') {
      return 'MICRO EN'
    }

    if (type === 'personal') {
      return 'SOLO EN'
    }
  }

  if (language_code === 'es') {
    if (type === 'business') {
      return 'MICRO ES'
    }

    if (type === 'personal') {
      return 'SOLO ES'
    }
  }

  return null
}
