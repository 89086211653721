// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--BsAtq";
export var content = "styles-module--content--8T3CJ";
export var container = "styles-module--container--GYXqj";
export var title = "styles-module--title--PkVCE";
export var disclaimer = "styles-module--disclaimer--2vHrb";
export var disclaimerPadding = "styles-module--disclaimerPadding--6knWH";
export var richParagraph = "styles-module--richParagraph--CbKJM";
export var richHeading4 = "styles-module--richHeading4--bAuJ9";
export var form = "styles-module--form--H5RIp";
export var results = "styles-module--results--w06YS";
export var richTextWrapper = "styles-module--richTextWrapper--sOb09";