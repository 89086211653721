import React from 'react'
import uniqid from 'uniqid'

import ChevronDownSVG from '@/assets/svg/chevron-down.svg'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { useElementHeight } from '@/base/hooks/useElementHeight'

import * as styles from './styles.module.css'

export const Accordion = ({ id, title, body }) => {
  const [ref, height] = useElementHeight()

  id = uniqid() // TODO: replace by the id from Contentful

  return (
    <div className={styles.accordion} style={{ '--height': height }}>
      <input type="checkbox" id={id} />
      <label htmlFor={id} className={styles.topBar}>
        <h3>{title}</h3>
        <div>
          <ChevronDownSVG width="30" />
        </div>
      </label>

      <div className={styles.collapsible}>
        <div ref={ref}>{getContentfulElementParagraph(body)}</div>
      </div>
    </div>
  )
}
