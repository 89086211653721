import React from 'react'

import { List } from '@/components/elements/List'
import { NoHardInquiryCTAButton } from '@/components/modules/CTA/NoHardInquiryCTAButton'
import { Section } from '@/components/modules/Section'
import { Step } from '@/components/modules/Step'

import * as styles from './styles.module.css'

export const Steps = ({ title, steps, cta }) => (
  <Section with-padding="true" with-background-decorators="true">
    <h2 className={styles.title} variant="micro">
      {title}
    </h2>

    <List items={steps} component={Step} className={styles.list} />

    <NoHardInquiryCTAButton {...cta} />
  </Section>
)
