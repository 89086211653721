import React, { useMemo } from 'react'
import { Carousel } from 'react-responsive-carousel'

import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'
import { RichText } from '@/components/elements/RichText'
import { Section } from '@/components/modules/Section'
import { BLOCKS } from '@contentful/rich-text-types'

import * as styles from './styles.module.css'

const FeatureItem = ({ title, icon, description, small }) => {
  return (
    <div className={`${styles.feature_card__wrapper} ${small ? styles.feature_card__wrapper_small : ''}`}>
      <div className={styles.feature_card__header}>
        {icon && (
          <div className={styles.feature_card__icon_wrapper}>
            <Image className={styles.feature_card__icon} image={icon} alt={icon.alt || title} />
          </div>
        )}
        {title && <p className={styles.feature_card__title}>{title}</p>}
      </div>
      {description && <p className={styles.feature_card__description}>{description}</p>}
    </div>
  )
}

export const Features = ({
  featuresSubtitle: subtitle,
  featuresTitle: title,
  featuresDescription: description,
  features,
  images,
  withGridLayout,
  callToAction,
}) => {
  const renderFeatures = useMemo(() => {
    if (!features) {
      return null
    }

    if (features.length > 0) {
      return (
        <div
          className={`
          ${styles.features__features_wrapper}
          ${withGridLayout ? styles.features__features_wrapper_grid : styles.features__features_wrapper_vertical}
          `}
        >
          {features.map(({ id, title, icon, description }) => (
            <FeatureItem key={id} title={title} icon={icon} description={description} small={!withGridLayout} />
          ))}
        </div>
      )
    }
  }, [withGridLayout, features])

  const renderImages = useMemo(() => {
    if (!images) {
      return null
    }

    if (images.length === 1) {
      const [image] = images
      return (
        <div className={styles.features__content__image}>
          <Image image={image} alt={image.title || title} />
        </div>
      )
    }

    if (images.length > 1) {
      return (
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          showArrows={false}
          swipeable={false}
          width={300}
          infiniteLoop
          autoPlay
        >
          {images.map((image) => (
            <div key={image.id} className={styles.features__content__image}>
              <Image image={image} alt={image.alt || title} />
            </div>
          ))}
        </Carousel>
      )
    }
  }, [images])

  function renderRichText(data, withGridLayout) {
    if (!data) {
      return null
    }

    return (
      <RichText
        data={data}
        options={{
          renderNode: {
            [BLOCKS.HEADING_3]: (_node, children) => <h3 className={styles.features__subtitle}>{children}</h3>,
            [BLOCKS.HEADING_2]: (_node, children) => (
              <h2
                className={`${styles.features__title} ${
                  withGridLayout ? styles.features__title_secondary : styles.features__title_primary
                }`}
              >
                {children}
              </h2>
            ),
            [BLOCKS.PARAGRAPH]: (_node, children) => <p className={styles.features__description}>{children}</p>,
          },
        }}
      />
    )
  }

  return (
    <Section className={styles.features__wrapper} with-arrow="true">
      {renderRichText(subtitle)}
      {renderRichText(title, withGridLayout)}
      <div className={`${styles.features__content} ${withGridLayout ? styles.features__content_with_grid : ''}`}>
        <div className={styles.features__content__text}>
          {renderRichText(description)}
          {renderFeatures}
        </div>
        {renderImages}
      </div>
      {callToAction && (
        <div className={styles.features__cta_wrapper}>
          <Link {...callToAction} variant="button-default" />
        </div>
      )}
    </Section>
  )
}
