import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { useIsMobile } from '@/base/hooks/useIsMobile'
import { Image } from '@/components/elements/Image'
import { Link as LinkVariant } from '@/components/elements/Link/getLinkFromVariant'
import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'
import { ShareIcons } from '@/components/modules/ShareIcons'

import * as styles from './styles.module.css'

export const HeroWithBackgroundImage = ({
  backgroundImageDesktop,
  backgroundImageMobile,
  body,
  isTitleLarge,
  title,
  subTitle,
  isTextAlignRight,
  isBackgroundShadow,
  shareUrl,
  backLink,
  buttons,
}) => {
  const isMobile = useIsMobile()
  return (
    <div className={styles.wrapper}>
      <Section
        className={styles.section}
        data-text-right={String(isTextAlignRight)}
        data-with-background-shadow={String(isBackgroundShadow)}
        data-text-center={String(buttons && buttons.length > 1)}
      >
        <div className={`${styles.container} ${backLink && styles.fullWidth}`}>
          {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
          {title && <h1 className={isTitleLarge ? styles.largeTitle : undefined}>{title}</h1>}
          {(backLink || shareUrl) && (
            <div className={styles.shareLinks}>
              <Link {...backLink} theme="white" variant="link-back" />
              <ShareIcons url={shareUrl} />
            </div>
          )}

          {getContentfulElementParagraph(body)}
          <List items={buttons} component={LinkVariant} className={styles.ctaWrapper} />
        </div>
      </Section>
      <div className={styles.image} style={{ minHeight: shareUrl ? '120px' : '534px' }}>
        <Image
          image={isMobile && backgroundImageMobile ? backgroundImageMobile : backgroundImageDesktop}
          loading="eager"
          objectFit="cover"
          objectPosition={!backgroundImageMobile ? '25% 0' : '50% 50%'}
        />
      </div>
    </div>
  )
}
