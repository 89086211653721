import React from 'react'

import CheckmarkSVG from '@/assets/svg/checkmark.svg'

import * as styles from './styles.module.css'

export const InputCheckbox = ({ label, value, onChange, defaultChecked, ...rest }) => {
  React.useEffect(() => {
    if (defaultChecked) {
      onChange?.({ target: { value: true } })
    }
  }, [defaultChecked])

  const toggleCheckbox = (e) => onChange?.({ target: { value: e.target.checked ? true : '' } })

  return (
    <label className={styles.wrapper}>
      <input
        type="checkbox"
        className={styles.input}
        onChange={toggleCheckbox}
        defaultChecked={defaultChecked}
        {...rest}
      />
      <span className={styles.checkmark}>
        <CheckmarkSVG height="10" width="12" />
      </span>
      <span className={styles.label}>{label}</span>
    </label>
  )
}
