import React from 'react'

import { Image } from '@/components/elements/Image'
import { List as ElementList } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'
import { Link } from '@/components/elements/Link'

import * as styles from './styles.module.css'

const ListingItem = ({ position, title, description }) => (
  <div className={styles.listItem}>
    <div className={styles.position}>
      <span>{position}</span>
    </div>
    <div>
      <h3>{title}</h3>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </div>
)

export const Listing12 = ({ label, title, items, cta }) => (
  <Section with-padding="true" className={styles.section} with-arrow="true">
    <p className={styles.label}>{label}</p>
    <h2 dangerouslySetInnerHTML={{ __html: title }} />
    <ElementList items={items} component={ListingItem} className={styles.list} />
    <div className={styles.cta}>
      <Link {...cta} variant="button-default" />
    </div>
  </Section>
)
