import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link/getLinkFromVariant'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const MainVariant02 = ({ backgroundImage, cta, body, title }) => (
  <div className={styles.wrapper}>
    <Section className={styles.section}>
      <div className={styles.container}>
        {title && <h1 className={styles.largeTitle}>{title}</h1>}
        {getContentfulElementParagraph(body)}
        <div className={styles.ctaWrapper}>{cta && <Link {...cta} />}</div>
      </div>
    </Section>
    <div className={styles.image} style={{ minHeight: '534px' }}>
      <Image image={backgroundImage} loading="eager" objectFit="cover" objectPosition={'50% 50%'} />
    </div>
  </div>
)
