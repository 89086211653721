import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'
import { Image } from '@/components/elements/Image'
import { Link } from '@/components/elements/Link'
import { Logos } from '@/components/modules/Logos'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

export const MainVariant04 = ({ title, body, cta, image, certifications }) => (
  <Section with-padding="true" with-shadow="true">
    <div className={styles.columns}>
      <div className={styles.heading}>
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        {getContentfulElementParagraph(body)}
      </div>

      <div className={styles.cta}>
        <Link {...cta} variant="button-default" />
      </div>

      {certifications && certifications.length > 0 && (
        <div className={styles.logos}>
          <Logos title="Certification" logos={certifications.map((image) => ({ image }))} />
        </div>
      )}
      {image && <Image image={image} className={styles.image} />}
    </div>
  </Section>
)
