import React from 'react'

import { Image } from '@/components/elements/Image'
import { List as ElementList } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'
import { Link } from '@/components/elements/Link'

import LinkedinSVG from '@/assets/icons/linkedin-icon.svg'
import * as styles from './styles.module.css'

const ListingItem = ({ icon, title, description, cta }) => (
  <div className={styles.listItem}>
    <Link {...cta} className={styles.imageContainer}>
      <Image image={icon} />
      <Image image={{ SVG: LinkedinSVG }} />
    </Link>
    <div className={styles.description}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
)

export const Listing15 = ({ title, items, cta }) => (
  <Section with-padding="true" className={styles.section}>
    <h2 dangerouslySetInnerHTML={{ __html: title }} />
    <ElementList items={items} component={ListingItem} className={styles.list} />
    <div className={styles.cta}>
      <Link {...cta} variant="button-default" />
    </div>
  </Section>
)
