import React from 'react'

import { Image } from '@/components/elements/Image'
import { List as ElementList } from '@/components/elements/List'
import { Section } from '@/components/modules/Section'

import * as styles from './styles.module.css'

const ListingItem = ({ position, description }) => (
  <div className={styles.listItem}>
    <div className={styles.position}>
      <span>{position}</span>
    </div>
    <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
  </div>
)

export const Listing11 = ({ title, items }) => (
  <Section with-padding="true" with-light-orange-background="true" className={styles.section}>
    <h2 dangerouslySetInnerHTML={{ __html: title }} />
    <ElementList items={items} component={ListingItem} className={styles.list} />
  </Section>
)
